import { ReactElement, useEffect } from 'react';
import { History } from 'history';
import { COOKIES } from '../../modules/common/data/enums';
import { ROUTES } from '../../config/routing/routes';
import { composePathWithParams } from '../../utils/url';
import { useParams } from 'react-router-dom';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    history: History;
    children: any;
    renderPublicLayout: any;
}

/**
 * Component that manages the standard layout of the application when the user
 * is not authenticated, this means that the pages are avaiable from the web by everyone
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {History} anonymousObject.history - Browser history object
 * @param {any} anonymousObject.renderPublicLayout - Method that renders the public layout
 * @param {any} anonymousObject.children - Component to be rendered as content of the public layout
 * @component
 * @category Components
 * @subcategory Layout
 * @returns {ReactElement | null}
 */
const PublicLayout = ({
    history,
    renderPublicLayout,
    children,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const { lang, idReseller }: any = useParams();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        console.log('[PublicLayout] init');

        if (!localStorage.getItem(COOKIES.INTRO)) {
            // Navigate to the intro screen
            history.push(composePathWithParams(ROUTES.INTRO, [{ lang, idReseller }]));
        }
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        console.log('[PublicLayout] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return renderPublicLayout(children, restProps);
};
export default PublicLayout;
