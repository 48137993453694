import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Divider, Drawer, Image, Modal } from 'antd';
import { ChoicesHistory } from '../../../data/interfaces';
import { useTranslation } from 'react-i18next';
import { Accessory, BaseType, Extension, ExtensionSide } from '@marinelli/shared-types';
import { STEP_COMPONENTS } from '../../../data/enums';
import { pruneEmpty } from '../../../utils/utils';
import useBases from '../../../hooks/useBases';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    choicesHistory?: ChoicesHistory;
    onChangeCurrentStep?: (stepContent: STEP_COMPONENTS) => void;
}

const SideMenu = ({
    choicesHistory,
    onChangeCurrentStep,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Access to bases manager hook.
    const { getAccessoriesTranslationKey } = useBases();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    // Side-Menu visibility state
    const [visible, setVisible] = useState<boolean>(false);

    const [selectedStep, setSelectedStep] = useState<STEP_COMPONENTS | undefined>(undefined);

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * To open the side-menu
     *
     * @function
     * @returns {void}
     */
    const handleOpen = (): void => {
        setVisible(true);
    };
    /**
     * To close the side-menu
     *
     * @function
     * @returns {void}
     */
    const handleClose = (): void => {
        setVisible(false);
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render layout choice section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderLayoutSection = (): ReactElement | null => {
        if (!choicesHistory?.layout) return null;
        return (
            <article title={t('props.title.sideMenu.layout')}>
                <div className="sideMenu-text-container">
                    <h6>{t('components.sideMenu.kitchenType')}</h6>
                    <div className="sideMenu-text">
                        <p className="sideMenu-text">
                            {t(`components.sideMenu.components.layout.${choicesHistory?.layout}`)}
                        </p>
                        <Button
                            type="link"
                            icon={<i className="ri-pencil-fill" />}
                            onClick={() => setSelectedStep(STEP_COMPONENTS.LAYOUT)}
                        />
                    </div>
                </div>
            </article>
        );
    };
    /**
     * Render configuration section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderConfigurationSection = (): ReactElement | null => {
        return (
            <article title={t('props.title.sideMenu.configuration')}>
                <div className="sideMenu-text-container">
                    <h6>{t('components.sideMenu.configuration.title')}</h6>
                    <div className="sideMenu-text">
                        <p className="sideMenu-text">
                            {t(`components.sideMenu.configuration.description`)}
                        </p>
                    </div>
                </div>
            </article>
        );
    };

    /**
     * Render handle choice section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderHandleSection = (): ReactElement | null => {
        if (!choicesHistory?.handle) return null;
        return (
            <>
                <article title={t('props.title.sideMenu.handle')}>
                    <div className="sideMenu-text-container">
                        <div className="sideMenu-text">
                            <p className="sideMenu-text">
                                {t(
                                    `components.sideMenu.components.handle.${choicesHistory?.handle}`,
                                )}
                            </p>
                            <Button
                                type="link"
                                icon={<i className="ri-pencil-fill" />}
                                onClick={() => setSelectedStep(STEP_COMPONENTS.HANDLE)}
                            />
                        </div>
                    </div>
                </article>
                <Divider />
            </>
        );
    };

    /**
     * Render oven choice section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderOvenSection = (): ReactElement | null => {
        if (!choicesHistory?.oven) return null;
        return (
            <>
                <article title={t('props.title.sideMenu.oven')}>
                    <div className="sideMenu-text-container">
                        <div className="sideMenu-text">
                            <p className="sideMenu-text">
                                {t(`components.sideMenu.components.oven.${choicesHistory?.oven}`)}
                            </p>
                            <Button
                                type="link"
                                icon={<i className="ri-pencil-fill" />}
                                onClick={() => setSelectedStep(STEP_COMPONENTS.OVEN)}
                            />
                        </div>
                    </div>
                </article>
                <Divider />
            </>
        );
    };

    /**
     * Render base choice section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderBaseSection = (): ReactElement | null => {
        if (!choicesHistory?.base) return null;
        return (
            <>
                <article title={t('props.title.sideMenu.base')}>
                    <div className="sideMenu-text-container">
                        <div className="sideMenu-text">
                            <p className="sideMenu-text">
                                {t(
                                    `components.sideMenu.components.base.${choicesHistory?.layout}`,
                                    {
                                        size1: choicesHistory?.base?.sideOne,
                                        size2: choicesHistory?.base?.sideTwo,
                                    },
                                )}
                            </p>
                            <Button
                                type="link"
                                icon={<i className="ri-pencil-fill" />}
                                onClick={() => setSelectedStep(STEP_COMPONENTS.BASES)}
                            />
                        </div>
                    </div>
                </article>
            </>
        );
    };

    /**
     * Render extensions choice section
     *
     * @param {ExtensionSide} side - The sides for which can be requested a list of extension
     * @function
     * @returns {ReactElement | null}
     */
    const renderExtensionsSection = (
        side: ExtensionSide = ExtensionSide.SIDE_ONE,
    ): ReactElement | null => {
        const extensionsProp = side === ExtensionSide.SIDE_ONE ? 'extensions1' : 'extensions2';
        if (
            !(
                (side === ExtensionSide.SIDE_ONE &&
                    !!choicesHistory?.extensions1 &&
                    choicesHistory.extensions1.length > 0) ||
                (side === ExtensionSide.SIDE_TWO &&
                    !!choicesHistory?.extensions2 &&
                    choicesHistory.extensions2.length > 0)
            )
        )
            return null;
        return (
            <>
                <article
                    title={t(
                        `props.title.sideMenu.extensions.${choicesHistory.layout}${
                            choicesHistory.layout === BaseType.LINEAR ? '' : `.${side}`
                        }`,
                    )}
                >
                    <div className="sideMenu-text-container">
                        <h6 className="sideMenu-title">
                            {t(
                                `components.sideMenu.components.extensions.${
                                    choicesHistory?.layout
                                }${choicesHistory?.layout === BaseType.ANGULAR ? `.${side}` : ''}`,
                            )}
                        </h6>
                        {choicesHistory[extensionsProp]?.map((ex: Extension) => (
                            <div className="sideMenu-text">
                                <p>
                                    {t(`ids.${ex.id}.title`)}
                                    <span> - {t(`ids.${ex.id}.size`)}</span>
                                </p>
                                <Button
                                    type="link"
                                    icon={<i className="ri-pencil-fill" />}
                                    onClick={() =>
                                        setSelectedStep(
                                            side === ExtensionSide.SIDE_ONE
                                                ? STEP_COMPONENTS.EXTENSIONS_1
                                                : STEP_COMPONENTS.EXTENSIONS_2,
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </article>
            </>
        );
    };
    /**
     * Render hob choice section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderHobSection = (): ReactElement | null => {
        if (!choicesHistory?.hob?.id) return null;
        return (
            <>
                <article title={t('props.title.sideMenu.accesories')}>
                    <div className="sideMenu-text-container">
                        <h6 className="sideMenu-title">
                            {t('components.sideMenu.components.accessories')}
                        </h6>
                        <div className="sideMenu-text">
                            <p className="sideMenu-text">
                                {t(`components.sideMenu.components.hob.${choicesHistory.hob.id}`)}
                            </p>
                            <Button
                                type="link"
                                icon={<i className="ri-pencil-fill" />}
                                onClick={() => setSelectedStep(STEP_COMPONENTS.HOB)}
                            />
                        </div>
                    </div>
                </article>
            </>
        );
    };
    /**
     * Render hob choice section
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderAccessoriesSection = (): ReactElement | null => {
        if (!(!!choicesHistory?.accessories && choicesHistory.accessories.length > 0)) return null;
        return (
            <>
                <article title={t('props.title.sideMenu.accessories')}>
                    <div className="sideMenu-text-container">
                        {!choicesHistory.hob && (
                            <h6 className="sideMenu-title">
                                {t('components.sideMenu.components.accessories')}
                            </h6>
                        )}
                        {choicesHistory.accessories?.map((a: Accessory) => (
                            <div className="sideMenu-text">
                                <p>
                                    {t(`ids.${getAccessoriesTranslationKey(a.id)}.title`)}
                                    <span>
                                        {' '}
                                        - {t(`ids.${getAccessoriesTranslationKey(a.id)}.size`)}
                                    </span>
                                </p>
                                <Button
                                    type="link"
                                    icon={<i className="ri-pencil-fill" />}
                                    onClick={() => {
                                        setSelectedStep(STEP_COMPONENTS.ACCESSORIES);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </article>
            </>
        );
    };

    /**
     * Render linear flow choices
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderLinearFlowChoices = (): ReactElement | null => (
        <>
            {renderLayoutSection()}
            {renderConfigurationSection()}
            {renderHandleSection()}
            {renderOvenSection()}
            {renderBaseSection()}
            {renderExtensionsSection()}
            {renderHobSection()}
            {renderAccessoriesSection()}
        </>
    );

    /**
     * Render angular flow choices
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderAngularFlowChoices = (): ReactElement | null => (
        <>
            {renderLayoutSection()}
            {renderConfigurationSection()}
            {renderHandleSection()}
            {renderBaseSection()}
            {renderExtensionsSection()}
            {renderExtensionsSection(ExtensionSide.SIDE_TWO)}
            {renderHobSection()}
            {renderAccessoriesSection()}
        </>
    );

    /**
     * Render the contents of the choices history
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderHistory = (): ReactElement | null => {
        if (!pruneEmpty(choicesHistory))
            // TRANSLATE ME
            return (
                <div className="noData-container">
                    <Image preview={false} src="/assets/images/undraw/noData.svg" />
                    <h6>{t('components.sideMenu.noData.title')}</h6>
                    <p className="sideMenu-text">{t('components.sideMenu.noData.description')}</p>
                </div>
            );

        return (
            <>
                {choicesHistory?.layout === BaseType.LINEAR
                    ? renderLinearFlowChoices()
                    : renderAngularFlowChoices()}

                <Modal
                    className="sideMenu-modal"
                    centered
                    visible={typeof selectedStep !== 'undefined'}
                    onCancel={() => setSelectedStep(undefined)}
                >
                    <Card>
                        <h5>{t('components.sideMenu.modal.title')}</h5>
                        <p>{t('components.sideMenu.modal.description')}</p>
                        <div className="modal-buttons-container">
                            <Button
                                type="link"
                                onClick={() => {
                                    if (typeof selectedStep === 'undefined' || !onChangeCurrentStep)
                                        return;
                                    onChangeCurrentStep(selectedStep);
                                    setSelectedStep(undefined);
                                    setVisible(false);
                                }}
                            >
                                {t('components.sideMenu.modal.buttons.confirm')}
                            </Button>
                            <Button type="primary" onClick={() => setSelectedStep(undefined)}>
                                {t('components.sideMenu.modal.buttons.cancel')}
                            </Button>
                        </div>
                    </Card>
                </Modal>
            </>
        );
    };
    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SideMenu] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SideMenu] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);
    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div className="side-menu-container">
            <div className="side-menu" data-testid="trigger" onClick={handleOpen}>
                <div className="cart-container">
                    <i className="icon-choices_list">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                        <span className="path6" />
                        <span className="path7" />
                        <span className="path8" />
                        <span className="path9" />
                    </i>
                    <h6>{t('components.sideMenu.list')}</h6>
                </div>
                <i className="ri-arrow-left-s-line" />
            </div>
            <Drawer
                visible={visible}
                placement="right"
                mask
                maskStyle={{ opacity: 0, animation: 'none' }}
                onClose={handleClose}
                closable
                getContainer={false}
                style={{ position: 'absolute' }}
                width="370px"
            >
                {visible && (
                    <div className="drawer-content" data-testid="drawer-content">
                        {renderHistory()}
                    </div>
                )}
            </Drawer>
        </div>
    );
};
export default SideMenu;
