import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';

export const ordersPath = `${baseUrl}/orders`;

export const createOrderResponseMock: any = {
    hello: 'world',
};

// Simulates a configuration with colors to be choose
const createOrderHandler = rest.get(ordersPath, async (req, res, ctx) =>
    res(ctx.json(createOrderResponseMock)),
);

// Returns an error exception
export const createOrderHandlerException = rest.get(ordersPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);

export const createOrderHandlers = [createOrderHandler];
