// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { translationsPath } from '../../mocks/handlers/translationsHandler';

/**
 * Returns the translation JSON files
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Translations
 * @param {string} lang - The iso code of the language for which to fetch translations
 * @returns {Promise<any>} // TODO: TYPE ME
 */
export const getTranlsationsFiles = async (lang: string): Promise<any> => {
    try {
        const params: any = {
            lang,
        };

        const response: AxiosResponse = await httpClient.get(translationsPath, {
            params,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err?.response?.data?.message;
    }
};
