import development from './development';
import staging from './staging';
import production from './production';
import { BrowserMinimumVersion } from '../modules/browser-compatibility/browserCompatibility';

/**
 * Environment configuration data
 *
 * @interface
 * @alias Environment
 * @category Interfaces
 * @subcategory Shared
 */
export interface Environment {
    /** Current version of the application */
    version: string;
    /** Applications urls */
    urls: {
        fo: string;
        bo: string;
    };
    /** Google Analytics account Universal Analytics identifier (UA) */
    idGoogleAnalytics: string;
    /** Configuration of Webservice parameters */
    api: {
        /** Absolute API endpoints base url */
        baseURL: string;
    };
    /** Configuration of assets */
    assets: {
        /** Logo path */
        logo: string;
    };
    /** Configuration of multilingual settings */
    i18n: {
        /** List of languages for which the site is translated (e.g. en, en, etc.). */
        languages: Array<string>;
        /** Default language in case the translation file of the set language is not found */
        fallbackLanguage: string;
    };
    /** Sentry account configuration */
    sentry: {
        /** Sentry dns absolute url */
        dsn: string;
        /** Defines whether or not to enable sending errors to Sentry */
        enabled: boolean;
    };
    /** Defines whether to print programmer logs to the console */
    showHandledLogs: boolean;
    /** Configuration of minimum browser versions, below which the application is not displayed */
    browserMinumumVersions: Array<BrowserMinimumVersion>;
}

const environment: Record<string, Environment> = {
    development,
    staging,
    production,
};

const index = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'development';

export default environment[index];
