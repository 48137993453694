import { setupWorker } from 'msw';
import { basesHandlers } from './handlers/basesHandler';
import { colorsHandlers } from './handlers/colorsHandler';
import { extensionsHandlers } from './handlers/extensionsHandler';
import { pricesHandlers } from './handlers/pricesHandler';
import { resellersHandlers } from './handlers/resellersHandler';
import { translationsHandlers } from './handlers/translationsHandler';

// This configures a Service Worker with the given request handlers.
export const worker = setupWorker(
    ...basesHandlers,
    ...extensionsHandlers,
    ...colorsHandlers,
    ...resellersHandlers,
    ...translationsHandlers,
    ...pricesHandlers,
);
