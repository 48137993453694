import { useEffect } from 'react';
import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import chroma from 'chroma-js';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------
interface useZoneData {
    changeAntdComponentsColor(hex: string): void;
}

/**
 * Custom hook
 *
 * @function
 * @class
 * @category Utils
 * @subcategory Hooks
 */
function useUiCustomization(): useZoneData {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Change the UI Customization interface of all antd UI components
     *
     * @function
     * @param {string} hex - Hex color
     * @returns {void}
     */
    const changeAntdComponentsColor = (hex: string): void => {
        const colors_primary_65 = chroma(hex).luminance(0.65).hex();
        // const colors_primary_75 = chroma(hex).luminance(0.75).hex();
        // const colors_primary_85 = chroma(hex).luminance(0.85).hex();
        // const colors_primary_95 = chroma(hex).luminance(0.95).hex();
        const colors_darken_95 = chroma(hex).darken(0.95).hex();

        changeAntdTheme(chroma(hex).hex(), {
            customCss: `
            .ant-btn-primary,
            .ant-card-body  .stepper-navigator >.ant-btn:not(.ant-btn-primary):hover,
            .ant-checkbox-checked,
            .steps-wrapper > .ant-steps > .ant-steps-item > .ant-steps-item-container .ant-steps-item-content > .ant-steps-item-title::after,
            .progress-bar-container .progress-bar > .ant-progress-steps > .ant-progress-steps-outer > .ant-progress-steps-item.ant-progress-steps-item-active,
            .steps-wrapper > .ant-steps > .ant-steps-item-process .ant-steps-item-icon {
                background-color: ${hex};
            }
            
            .ant-card#selector > .ant-card-body .card-list > .action-card > .normal-action-card > .ant-card-body > .ant-btn,
            .steps-wrapper > .ant-steps > .ant-steps-item-process .ant-steps-item-icon,
            .steps-wrapper > .ant-steps > .ant-steps-item-finish .ant-steps-item-icon,
            .ant-checkbox-inner,
            .side-menu,
            .ant-card-body  .stepper-navigator >.ant-btn {
                border-color: ${hex};
            }
            
            .button-default,
            .ri-delete-bin-fill,
            .ant-card-body > .ant-row > #right-column > .ant-list .ant-list-items >.ant-list-item >i,
            .ant-card-body  .stepper-navigator >.ant-btn:not(.ant-btn-primary),
            .side-menu i,
            .side-menu i.ri-arrow-left-s-line::before,
            .side-menu >.cart-container > h6,
            .ant-drawer-content >.ant-drawer-wrapper-body .ant-drawer-close >.anticon >svg,
            .ant-table-tbody > .ant-table-row >.ant-table-cell > a,
            .steps-wrapper > .ant-steps > .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
                color: ${hex};
            }

            .steps-wrapper > .ant-steps > .ant-steps-item-process .ant-steps-item-icon .ant-progress > .ant-progress-inner > .ant-progress-circle > .ant-progress-circle-path {
                stroke: ${hex};
            }

            .ant-btn-primary:hover,
            .ant-btn-primary:active {
                background-color: ${colors_darken_95};
            }
            
            .action-card > .normal-action-card > .ant-card-body > .ant-btn-primary:hover{
                border-color: ${colors_darken_95};
            }
            
            .ri-delete-bin-fill::before {
                color: ${colors_darken_95};
            }
            

            .uncheck{
                background-color: ${colors_primary_65};
            }

        
            .uncheck{
                border-color: ${colors_primary_65};
            }


`,
        });
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[useUiCustomization] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[useUiCustomization] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return {
        changeAntdComponentsColor,
    };
}

export default useUiCustomization;
