import { ROUTES, ROUTES_KEYS } from './routes';
import PublicLayout from '../../routing/layout/PublicLayout';
import { Page } from '../../routing/data/interfaces';
import PrivacyPolicyView from '../../screens/public/privacyPolicy';
import CookiePolicyView from '../../screens/public/cookiePolicy';
import TermsOfServiceView from '../../screens/public/termsOfService';
import IntroView from '../../screens/public/intro';
import SelectorIndexView from '../../screens/public/selector/index';
// import SelectorIndexView from '../../screens/public/selector';

// Pages list
export const PAGES: Array<Page> = [
    // Static pages
    {
        exact: false,
        path: ROUTES.PRIVACY_POLICY,
        key: ROUTES_KEYS.PRIVACY_POLICY,
        auth: false,
        alwaysVisible: true,
        component: PrivacyPolicyView,
        layout: PublicLayout,
    },
    {
        exact: false,
        path: ROUTES.COOKIE_POLICY,
        key: ROUTES_KEYS.COOKIE_POLICY,
        auth: false,
        alwaysVisible: true,
        component: CookiePolicyView,
        layout: PublicLayout,
    },
    {
        exact: false,
        path: ROUTES.TERMS_OF_SERVICE,
        key: ROUTES_KEYS.TERMS_OF_SERVICE,
        auth: false,
        alwaysVisible: true,
        component: TermsOfServiceView,
        layout: PublicLayout,
    },
    {
        exact: false,
        path: ROUTES.INTRO,
        key: ROUTES_KEYS.INTRO,
        auth: false,
        alwaysVisible: true,
        component: IntroView,
        layout: PublicLayout,
    },

    // Params pages
    {
        exact: true,
        path: ROUTES.HOME,
        key: ROUTES_KEYS.HOME,
        auth: false,
        component: SelectorIndexView,
        layout: PublicLayout,
    },
];
