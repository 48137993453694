// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { IGetColors } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ColorsResponse } from '../../data/interfaces';
import { colorsPath } from '../../mocks/handlers/colorsHandler';

/**
 * Returns configuration according to choises history
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IGetColors} data - Input data
 * @returns {Promise<ColorsResponse>}
 */
export const getColors = async (data: IGetColors): Promise<ColorsResponse> => {
    try {
        const response: AxiosResponse = await httpClient.get(colorsPath, {
            params: data,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err?.response?.data?.message;
    }
};
