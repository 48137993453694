import { Reseller } from '@marinelli/shared-types';

export enum CONTEXT_RESELLER_TYPES {
    PENDING = 'CONTEXT_RESELLER_PENDING',
    COMPLETED = 'CONTEXT_RESELLER_COMPLETED',
    ADD_ERROR = 'CONTEXT_RESELLER_ADD_ERROR',
    CLEAR_ERROR = 'CONTEXT_RESELLER_CLEAR_ERROR',
}

interface Pending {
    type: CONTEXT_RESELLER_TYPES.PENDING;
}

interface Completed {
    type: CONTEXT_RESELLER_TYPES.COMPLETED;
    payload: Partial<Reseller>;
}

interface AddError {
    type: CONTEXT_RESELLER_TYPES.ADD_ERROR;
    payload: string;
}

interface ClearError {
    type: CONTEXT_RESELLER_TYPES.CLEAR_ERROR;
}

export type ContextResellerAction = Pending | Completed | AddError | ClearError;
