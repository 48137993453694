import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, List, Dropdown, Menu, Button, Empty, Spin, Image } from 'antd';
import LayoutWithSidebar from '../../../../../components/layout/layoutWithSidebar/layoutWithSidebar';
import { ChoicesHistory } from '../../../../../data/interfaces';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';
import { Accessory, Color, Configuration, ConfigurationImages } from '@marinelli/shared-types';
import { useGetColorsQuery } from '../../../../../hooks/react-query/useGetColorsQuery/useGetColorsQuery';
import { colors as mockColors } from '../../../../../mocks/data';
import { useParams } from 'react-router-dom';
import MarinelliImage from '../../../../../components/layout/image/image';
import useBases from '../../../../../hooks/useBases';
import { mapIndexToAlphabet } from '../../../../../utils/utils';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    choicesHistory: ChoicesHistory;
    stepperProps: StepperProps;
    onUpdateConfiguration: (configuration?: Configuration) => void;
}

/**
 * View representing the kitchen colors to pick
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {(configuration: Configuration) => void} anonymousObject.onUpdateConfiguration - Called when configuration is fetched
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SelectorColorsView = ({
    choicesHistory,
    stepperProps,
    onUpdateConfiguration,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [chosenColors, setChosenColors] = useState<Array<Color>>(choicesHistory.colors || []);
    const [colorPaletteNumber, setColorPaletteNumber] = useState<number | undefined>(undefined);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const { idReseller }: any = useParams();

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Access to bases manager hook.
    const { getBaseImageUrl } = useBases();

    // Subscription to a fetch query
    const {
        data: colorsResponse,
        refetch,
        isSuccess: isFetchingColorsSucceded,
        isFetching: isFetchingColors,
        isError: isFetchingColorsFailed,
    } = useGetColorsQuery({
        configId: choicesHistory.configuration?.id || '',
        accessories: choicesHistory.accessories?.map((a: Accessory) => a.id),
        resellerId: `${idReseller}`,
        colors: chosenColors.map((c: Color) => c.id),
    });

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Get the selected color
     *
     * @param {Array<Color>} colors -
     * @param {number} i -
     * @function
     * @returns {void}
     */
    const getSelectedColor = (colors: Array<Color>, i: number): void => {
        setChosenColors(colors);
        setColorPaletteNumber(i);
    };
    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render left column content
     *
     * @function
     * @returns {ReactElement}
     */
    const renderLeftColumn = (): ReactElement | null => {
        if (!(!!choicesHistory.base && !!choicesHistory.direction)) return null;
        return (
            <>
                {!colorsResponse?.configuration ? (
                    <Empty
                        description={t('screens.selector.steps.colors.configuration.noData')}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                ) : (
                    <>
                        <div className="image-container">
                            <MarinelliImage
                                src={[
                                    choicesHistory.configuration?.imagesUrl[
                                        ConfigurationImages.COLORED
                                    ] || '',
                                    getBaseImageUrl(choicesHistory.base, choicesHistory.direction),
                                ]}
                                preview
                                size={500}
                            />
                            <div className="note-container">
                                <h6>{t('screens.selector.steps.colors.note.title')}</h6>
                                <p>
                                    {/* TODO: TRANSLATE ME */}
                                    {t('screens.selector.steps.colors.note.text')}
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    /**
     * Render right column content
     *
     * @function
     * @returns {ReactElement}
     */
    const renderRightColumn = (): ReactElement => (
        <>
            <Card>
                <h6>{t('screens.selector.steps.colors.card.title')}</h6>
                <Dropdown
                    overlay={
                        <Menu>
                            {colorsResponse?.colors.length === 0 ? (
                                <Empty
                                    description={t('screens.selector.steps.colors.noData')}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ) : (
                                <>
                                    {colorsResponse?.colors.map(
                                        (colors: Array<Color>, i: number) => (
                                            <Menu.Item
                                                title={t('props.title.colors.dropdown.select')}
                                                key={colors.map((c: Color) => c.id).join('-')}
                                                onClick={() => getSelectedColor(colors, i)}
                                            >
                                                <a>
                                                    {t(
                                                        'screens.selector.steps.colors.card.dropdown.paletteTitle',
                                                        { index: mapIndexToAlphabet(i) },
                                                    )}
                                                </a>
                                                <div className="color-images-group">
                                                    {colors.map((color: Color) => (
                                                        <MarinelliImage
                                                            alt={`${color.id} selectable color`}
                                                            preview={false}
                                                            src={[color.imageUrl]}
                                                        />
                                                    ))}
                                                </div>
                                            </Menu.Item>
                                        ),
                                    )}
                                </>
                            )}
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Button title={t('props.title.colors.dropdown.button')}>
                        {colorPaletteNumber !== undefined
                            ? t('screens.selector.steps.colors.card.dropdown.paletteTitle', {
                                  index: mapIndexToAlphabet(colorPaletteNumber),
                              })
                            : t('screens.selector.steps.colors.card.dropdown.title')}
                        <i className="ri-arrow-down-s-line" />
                    </Button>
                </Dropdown>
                <List
                    dataSource={chosenColors.length > 0 ? chosenColors : mockColors}
                    renderItem={(color: Color, i: number) => (
                        <List.Item key={`${i}_${color.id}`}>
                            <div className="color-image-container">
                                {chosenColors.length > 0 ? (
                                    <MarinelliImage
                                        alt={`${color.id} chosen color`}
                                        src={[color.imageUrl]}
                                        preview
                                    />
                                ) : (
                                    <Image
                                        src="https://via.placeholder.com/273x225?text=?"
                                        preview={false}
                                    />
                                )}
                            </div>
                            <div className="color-text-container">
                                <h6 title={t('props.title.colors.list.title')}>
                                    {t(
                                        `screens.selector.steps.colors.card.list.${
                                            i === 0 ? 'base' : i === 1 ? 'top' : 'kitchenCabinet'
                                        }`,
                                    )}
                                </h6>
                                <p title={t('props.title.colors.list.description')}>
                                    {t(
                                        chosenColors.length > 0
                                            ? `ids.${color.id}`
                                            : 'screens.selector.steps.colors.card.list.noData',
                                    )}
                                </p>
                            </div>
                        </List.Item>
                    )}
                />
            </Card>
        </>
    );

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SelectorColorsView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SelectorColorsView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to chosenColors in order to refetch data
     */
    useEffect(() => {
        refetch();
    }, [chosenColors]);

    /**
     * Hook to colorsResponse in order to update history configuration
     */
    useEffect(() => {
        if (!!colorsResponse) {
            onUpdateConfiguration(colorsResponse.configuration);
        }
    }, [colorsResponse]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-step-colors">
            <StepperNavigator
                onPrevious={stepperProps.onPrevious}
                onNext={
                    chosenColors.length > 0
                        ? () => {
                              if (!!stepperProps.onNext) stepperProps.onNext(chosenColors);
                          }
                        : undefined
                }
            />
            <header className="card-header large">
                <h4 className="card-title"> {t('screens.selector.steps.colors.title')} </h4>
            </header>
            {isFetchingColors ? (
                <div className="loader" style={{ height: 200 }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {isFetchingColorsFailed && (
                        <Empty
                            description={t('screens.selector.steps.colors.noData')}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                    {isFetchingColorsSucceded && !!colorsResponse && (
                        <>
                            <LayoutWithSidebar
                                leftContent={renderLeftColumn()}
                                rightContent={renderRightColumn()}
                                reverse
                                sizes={{
                                    left: {
                                        sm: 12,
                                        md: 13,
                                        lg: 12,
                                        xl: 12,
                                    },
                                    right: {
                                        sm: 12,
                                        md: 11,
                                        lg: 11,
                                        xl: 10,
                                    },
                                }}
                                offset={{
                                    right: {
                                        lg: 1,
                                        xl: 2,
                                    },
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default SelectorColorsView;
