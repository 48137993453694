import { IGetBases, KitchenBase } from '@marinelli/shared-types';
import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../../modules/common/data/enums';
import { getBases } from '../../../redux/bases/action';
import useQueries from '../../useQueries';

/**
 * @param {IGetBases} data - Input data
 * @returns {UseQueryResult<Array<KitchenBase>, any>}
 */
export const useGetBasesQuery = (data: IGetBases): UseQueryResult<Array<KitchenBase>, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_BASES],
        async () => {
            return getBases(data);
        },
        getFetchRequestQueryParams({
            retry: !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development',
        }),
    );
};
