import { Card, Button, Tooltip, Spin, Image } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { imageExists } from '../../../utils/url';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


export interface ActionCardI {
    id: string;
    title?: string;
    description?: string;
    imageUrl?: string;
    zoomable?: boolean;
    bottomText?: string | ReactElement;
    defaultImagePath?: string;
    buttonTitle?: string;
    onSelect?: (selectedItemIdIgnored: any) => void;
}


interface Props {
    actionCard: ActionCardI;
    imageClasses?: string;
}

/**
 * Component that takes care of rendering some given info via the ActionCardI interface
 *
 * @param {ActionCardI} actionCard - COMMENT ME
 * @component
 * @category Components
 * @subcategory Fragment
 * @returns {ReactElement | null}
 */
const ActionCard = ({ actionCard, imageClasses }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [zoomed, setZoomed] = useState(false);
    // const [realUrl, setRealUrl] = useState<string | undefined>(undefined);
    const [realUrl] = useState<string | undefined>(actionCard.imageUrl);

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------

    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ActionCard] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ActionCard] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /* useEffect(() => {
        if (!!actionCard.imageUrl) {
            (async () => {
                setRealUrl(await imageExists(actionCard.imageUrl));
            })();
        }
    }, [actionCard.imageUrl]); */

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return typeof realUrl !== 'undefined' ? (
        <div className="action-card">
            <Card
                key={actionCard.id}
                className={`${actionCard.zoomable ? 'zoomable-action-card' : 'normal-action-card'}`}
            >
                {!!actionCard.title && (
                    <div className="card-header">
                        <h4 className="card-title">{actionCard.title}</h4>
                        <div className="card-description">
                            <p>{actionCard.description}</p>
                            {actionCard.id === 'external' && (
                                <Tooltip
                                    title={t(
                                        'screens.selector.steps.structure.handle.card.external.tooltip',
                                    )}
                                >
                                    <i className="ri-information-fill" />
                                </Tooltip>
                            )}
                            {actionCard.id === 'internal' && (
                                <Tooltip
                                    title={t(
                                        'screens.selector.steps.structure.handle.card.internal.tooltip',
                                    )}
                                >
                                    <i className="ri-information-fill" />
                                </Tooltip>
                            )}
                            {actionCard.id === 'under' && (
                                <Tooltip
                                    title={t(
                                        'screens.selector.steps.structure.oven.card.under.tooltip',
                                    )}
                                >
                                    <i className="ri-information-fill" />
                                </Tooltip>
                            )}
                            {actionCard.id === 'column' && (
                                <Tooltip
                                    title={t(
                                        'screens.selector.steps.structure.oven.card.column.tooltip',
                                    )}
                                >
                                    <i className="ri-information-fill" />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}
                <div
                    className={`${imageClasses || 'background-image'}`}
                    style={{
                        /* backgroundImage: `url(${
                            realUrl ||
                            "'https://via.placeholder.com/250/32495C/FFFFFF?text=No image'"
                        })`, */
                        backgroundImage: `url(${realUrl})`,
                    }}
                >
                    {actionCard.zoomable && !!realUrl && (
                        <Image
                            src="/assets/images/icons/zoom.png"
                            preview={
                                actionCard.zoomable
                                    ? {
                                          visible: zoomed,
                                          src: `${realUrl}?high_res=true`,
                                          onVisibleChange: (vis) => setZoomed(vis),
                                      }
                                    : undefined
                            }
                            alt={`${actionCard.id} action card image`}
                        />
                    )}
                </div>

                <Button
                    type="primary"
                    title={
                        actionCard.buttonTitle ||
                        t(`components.cards.title`, { title: t(`ids.${actionCard.id}`) })
                    }
                    size="large"
                    onClick={() => {
                        if (!actionCard.onSelect) return;
                        actionCard.onSelect(actionCard.id);
                    }}
                >
                    <h6>{t('components.cards.button')}</h6>
                </Button>
            </Card>
            {!!actionCard.bottomText && (
                <div className="bottom-text" title="bottom-text">
                    {actionCard.bottomText}
                </div>
            )}
        </div>
    ) : (
        <Spin size="large" />
    );
};
export default ActionCard;
