import { Card, Image, Button, Spin } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ContextResellerState } from '../../../redux/resellers/contextReseller/reducer';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../config/routing/routes';
import { COOKIES } from '../../../modules/common/data/enums';
import { composePathWithParams } from '../../../utils/url';
import MarinelliImage from '../../../components/layout/image/image';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

/**
 * View representing the Welcome Page of the selector
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const IntroView = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------
    // Get data from the authReducer store state
    const { reseller } = useSelector(
        ({ contextResellerReducer }: { contextResellerReducer: ContextResellerState }) =>
            contextResellerReducer,
    );

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to the history instance that you may use to navigate.
    const history = useHistory();

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const { lang, idReseller }: any = useParams();

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s

    // -----------------------------------------------------------------
    /**
     * Method that takes you to the first step of the kitchen selector
     *
     * @function
     * @returns {void}
     */
    const handleEnterButtonClick = (): void => {
        // Set the cookie that will hide this screen for the next few times
        localStorage.setItem(COOKIES.INTRO, 'true');
        // Sets a timeout to remove the COOKIE/Nonce
        setTimeout(() => localStorage.removeItem(COOKIES.INTRO), 250);

        // Navigate to the first step of the selector
        history.push(composePathWithParams(ROUTES.HOME, [{ lang, idReseller }]));
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component

        console.log('[IntroView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[IntroView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return !!reseller ? (
        <div id="selector-intro">
            <div
                className="background-intro"
                style={{ backgroundImage: `url(${reseller.introBgUrl})` }}
            />
            <Card className="intro-center-card">
                {!reseller?.is_embedded_in_iframe && (
                    <MarinelliImage
                        src={[reseller?.logoUrl || '']}
                        className="owner-logo"
                        data-testid="reseller-logo"
                        title={`${reseller?.name} logo`}
                    />
                )}
                <div className="text">
                    <h2>{t('screens.intro.title')}</h2>
                    <p>{t('screens.intro.description')}</p>
                </div>
                <Button
                    onClick={handleEnterButtonClick}
                    type="primary"
                    title={t('props.title.intro.button')}
                >
                    <h6>{t('screens.intro.button')}</h6>
                </Button>
            </Card>
            {reseller.show_mrn_logos ? (
                <div className="intro-logo">
                    <Image src="/assets/images/resources/logo/mimo.png" preview={false} />
                    <Image src="/assets/images/resources/logo/marinelli.png" preview={false} />
                </div>
            ) : undefined}
        </div>
    ) : (
        <Spin size="large" />
    );
};
export default IntroView;
