/* eslint-disable no-lonely-if */
import React, { ReactElement, useEffect, useState } from 'react';
import ActionCard from '../../../../../components/cards/actionCard/actionCard';
import { useTranslation } from 'react-i18next';
import { ChoicesHistory } from '../../../../../data/interfaces';
import useBases from '../../../../../hooks/useBases';
import { Empty, Spin, Switch } from 'antd';
import { BaseType, HandleType, KitchenBase, PossibleDirection } from '@marinelli/shared-types';
import { useGetBasesQuery } from '../../../../../hooks/react-query/useGetBasesQuery/useGetBasesQuery';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';
import { useParams } from 'react-router-dom';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    stepperProps: StepperProps;
    onSelect: (dataIgnored: KitchenBase, direction: PossibleDirection) => void;
    choicesHistory: ChoicesHistory;
}

/**
 * View representing the kitchen base options from which to choose to start configuring the kitchen.
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {(dataIgnored: BaseType) => void} anonymousObject.onSelect - Called when an element is selected
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SelectorStepBaseView = ({
    onSelect,
    stepperProps,
    choicesHistory,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [direction, setDirection] = useState<PossibleDirection>(
        choicesHistory.direction || PossibleDirection.L2R,
    );

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to bases manager hook.
    const { mapBaseIntoActionCard } = useBases();

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const { idReseller }: any = useParams();

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Subscription to a fetch query
    const {
        data: bases,
        refetch,
        isSuccess: isFetchingBasesSucceded,
        isFetching: isFetchingBases,
        isError: isFetchingBasesFailed,
    } = useGetBasesQuery({
        resellerId: `${idReseller}`,
        baseType: choicesHistory.layout || BaseType.LINEAR,
        sideOneFinal: choicesHistory.maxLength1,
        handleType: choicesHistory.handle || HandleType.INTERNAL,
        ovenType: choicesHistory.oven,
        mountDirection: direction,
    });

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SelectorStepBaseView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SelectorStepBaseView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the cowmponent is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to direction in order to refetch data
     */
    useEffect(() => {
        refetch();
    }, [direction]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-step-bases">
            <StepperNavigator onPrevious={stepperProps.onPrevious} onNext={stepperProps.onNext} />
            <header className="card-header large">
                <h4 className="card-title"> {t('screens.selector.steps.base.title')} </h4>
            </header>
            {isFetchingBases ? (
                <div className="loader" style={{ height: 200 }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {isFetchingBasesFailed && (
                        <Empty
                            description={t('screens.selector.steps.base.noData')}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                    {isFetchingBasesSucceded && !!bases && (
                        <>
                            {bases.length === 0 ? (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : (
                                <>
                                    <div className="toggle-container">
                                        <p>{t('screens.selector.steps.base.toggle')}</p>
                                        <Switch
                                            size="small"
                                            checked={direction === PossibleDirection.R2L}
                                            onChange={(isSelected: boolean) =>
                                                setDirection(
                                                    isSelected
                                                        ? PossibleDirection.R2L
                                                        : PossibleDirection.L2R,
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="card-list">
                                        {bases.map((b: KitchenBase) => (
                                            <ActionCard
                                                key={b.id}
                                                actionCard={mapBaseIntoActionCard(
                                                    b,
                                                    () => {
                                                        onSelect(b, direction);
                                                    },
                                                    direction,
                                                )}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default SelectorStepBaseView;
