import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import contextResellerReducer from '../redux/resellers/contextReseller/reducer';

let store: Store;

const rootReducer = combineReducers({ contextResellerReducer });

const persistConfig = {
    key: 'root',
    storage,
    timeout: 0,
    whitelist: ['contextResellerReducer'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
    store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
} else {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();
    store = createStore(
        persistedReducer,
        compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
    );
}

const persistor = persistStore(store);
export { store, persistor };
