/**
 * Query params variables
 *
 * @enum {string}
 * @alias QueryParams
 * @category Enum
 */
export enum QUERY_PARAMS {
    LANGUAGE = 'lang',
    ID_RESELLER = 'id',
}

/**
 * The setp to be undertaken to schedule an appointment (?)
 *
 * @enum {string}
 * @alias Selector Steps
 * @category Enum
 */
export enum SELECTOR_STEPS {
    TYPES = 'types',
    STRUCTURE = 'structure',
    BASE = 'base',
    EXTENSIONS = 'extensions',
    ACCESSORIES = 'accessories',
    COLORS = 'colors',
    SUMMARY = 'summary',
}

/**
 * Rendered components as content of individual steps
 *
 * @enum {string}
 * @alias Selector StepComponents
 * @category Enum
 */
export enum STEP_COMPONENTS {
    ACCESSORIES,
    BASES,
    EXTENSIONS_1,
    EXTENSIONS_2,
    HANDLE,
    HOB,
    LAYOUT,
    LENGTH_1,
    LENGTH_2,
    OVEN,
    COLORS,
    SUMMARY,
}
