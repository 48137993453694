import { Image, Spin } from 'antd';
import React, { CSSProperties, ReactElement, useEffect } from 'react';
// import { imageExists } from '../../../utils/url';
import { useQuery } from 'react-query';
import useQueries from '../../../hooks/useQueries';
import { QUERIES_KEYS } from '../../../modules/common/data/enums';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    src?: Array<string>;
    preview?: boolean;
    style?: CSSProperties;
    alt?: string;
    className?: string;
    title?: string;
    size?: number;
}

/**
 * Component that takes care of rendering the cookie policy banner
 * to be accepted by the user.
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {string} anonymousObject.src - COMMENT ME
 * @param {boolean} anonymousObject.preview - COMMENT ME
 * @param {CSSProperties} anonymousObject.style - COMMENT ME
 * @param {string} anonymousObject.alt - COMMENT ME
 * @param {string} anonymousObject.className - COMMENT ME
 * @param {string} anonymousObject.title - COMMENT ME
 * @param {number} anonymousObject.size - COMMENT ME
 * @component
 * @category Components
 * @subcategory Fragment
 * @returns {ReactElement | null}
 */
const MarinelliImage = ({
    src,
    preview = false,
    style,
    alt,
    className,
    title,
    // size = 300,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // const [realUrl, setRealUrl] = useState<string | undefined>(undefined);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    const { getFetchRequestQueryParams }: any = useQueries();

    const { data: realUrl } = useQuery(
        [QUERIES_KEYS.VALIDATE_IMAGE, src],
        async () => {
            if (!!src) {
                for (let i = 0; i < src.length; i += 1) {
                    if (!!src[i]) {
                        /* eslint-disable no-await-in-loop */
                        // const validatedUrl = await imageExists(src[i]);
                        const validatedUrl = src[i];

                        if (!!validatedUrl) {
                            return validatedUrl;
                        }
                        /* eslint-disable no-else-return */
                        return '';
                    } else {
                        /* eslint-disable no-lonely-if */
                        if (i === src.length - 1) {
                            return '';
                        }
                    }
                }
            } else {
                return '';
            }
        },
        {
            ...getFetchRequestQueryParams({
                // enabled: false,
                staleTime: 3600000, // 1 hour
                retry: !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development',
            }),
        },
    );

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------

    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[MarinelliImage] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[MarinelliImage] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * TODO: COMMENT ME
     */
    // useEffect(() => {
    //     if (!!src) {
    //         (async () => {
    //             for (let i = 0; i < src.length; i += 1) {
    //                 if (!!src[i]) {
    //                     /* eslint-disable no-await-in-loop */
    //                     await triggerCheckImage(src[i]);
    //                 }
    //             }
    //         })();
    //     }
    //     setRealUrl('');
    // }, [src]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return typeof realUrl !== 'undefined' ? (
        <Image
            // fallback={`https://via.placeholder.com/${size}/32495C/FFFFFF?text=No image`}
            src={realUrl}
            style={style}
            preview={preview && { src: `${realUrl}?high_res=true` }}
            alt={alt}
            className={className}
            title={title}
            {...restProps}
        />
    ) : (
        <Spin size="large" />
    );
};
export default MarinelliImage;
