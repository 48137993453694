import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, List, Modal, notification, Spin, Empty, Button, Tooltip } from 'antd';
import LayoutWithSidebar from '../../../../../components/layout/layoutWithSidebar/layoutWithSidebar';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { ChoicesHistory } from '../../../../../data/interfaces';
import Slider from 'react-slick';
import { useGetExtensionsQuery } from '../../../../../hooks/react-query/useGetExtensionsQuery/useGetExtensionsQuery';
import {
    BaseType,
    Configuration,
    ConfigurationImages,
    Extension,
    ExtensionSide,
    HandleType,
    PossibleDirection,
} from '@marinelli/shared-types';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';
import MarinelliImage from '../../../../../components/layout/image/image';
import { QUERIES_KEYS } from '../../../../../modules/common/data/enums';
import i18n from 'i18next';
import { usePreviousDistinct } from 'react-use';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    side: ExtensionSide;
    stepperProps: StepperProps;
    choicesHistory: ChoicesHistory;
    onUpdateConfiguration: (configuration?: Configuration) => void;
}

/**
 * View representing the possible kitchen extensions to add
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {ExtensionSide} anonymousObject.side - Kitchen side
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {(configuration: Configuration) => void} anonymousObject.onUpdateConfiguration - Called when configuration is fetched
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SelectorStepExtensionsView = ({
    side,
    stepperProps,
    choicesHistory,
    onUpdateConfiguration,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [isModalVisible, setModalVisible] = useState(false);
    const [chosenExtensions, setChosenExtensions] = useState<Array<Extension>>(
        (side === ExtensionSide.SIDE_ONE
            ? choicesHistory.extensions1
            : choicesHistory.extensions2) || [],
    );
    const previousChosenExtensions = usePreviousDistinct(chosenExtensions);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const { queryClient }: any = restProps;

    // Antd topHeightOver notification key
    const topHeightOverAlertKey = 'extension-alert';

    // Antd noConfiguration notification key
    const noConfigurationAlertKey = 'no-onfiguration-alert';

    // Antd accessories notification key
    const accessoriesAlertKey = 'extension-alert';

    // Subscription to a fetch query
    const {
        data: extensionsResponse,
        refetch,
        isSuccess: isFetchingExtensionsSucceded,
        isFetching: isFetchingExtensions,
        isError: isFetchingExtensionsFailed,
    } = useGetExtensionsQuery({
        side,
        kitchenBaseId: choicesHistory.base?.id || '',
        baseType: choicesHistory.layout || BaseType.LINEAR,
        sideOneFinal: choicesHistory.maxLength1 || 0,
        sideTwoFinal: choicesHistory.maxLength2,
        handleType: choicesHistory.handle || HandleType.INTERNAL,
        ovenType: choicesHistory.oven,
        sideOneExtensionIds:
            side === ExtensionSide.SIDE_ONE
                ? chosenExtensions.map((ex: Extension) => ex.id)
                : choicesHistory.extensions1?.map((ex: Extension) => ex.id),
        sideTwoExtensionIds:
            side === ExtensionSide.SIDE_ONE
                ? undefined
                : chosenExtensions.map((ex: Extension) => ex.id),
    });

    // Reference to Slider component to access all methods and properties
    const sliderRef = useRef<Slider>(null);

    // Slider settings configurations
    const slickSliderSettings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Open the top height over notification alert
     *
     * @function
     * @returns {void}
     */
    const openTopHeightOverAlert = (): void => {
        notification.config({
            maxCount: 1,
        });
        notification.warning({
            key: topHeightOverAlertKey,
            message: <h6>{t('screens.selector.steps.extensions.alert.title')}</h6>,
            description: t('screens.selector.steps.extensions.alert.top_height_over'),
            duration: 0,
            placement: 'bottomRight',
        });
    };

    /**
     * Open the no configuration notification alert
     *
     * @function
     * @returns {void}
     */
    const openNoConfigurationAlert = (): void => {
        notification.config({
            maxCount: 1,
        });
        notification.info({
            key: noConfigurationAlertKey,
            message: <h6>{t('screens.selector.steps.extensions.alert.title')}</h6>,
            description: t('screens.selector.steps.extensions.alert.no_configuration'),
            duration: 0,
            placement: 'topRight',
        });
    };

    /**
     * Open the accessories notification alert
     *
     * @function
     * @returns {void}
     */
    const openAccessoriesAlert = (): void => {
        notification.config({
            maxCount: 1,
        });
        notification.warning({
            key: accessoriesAlertKey,
            message: <h6>{t('screens.selector.steps.extensions.alert.title')}</h6>,
            description: t('screens.selector.steps.extensions.alert.accessories'),
            duration: 10,
            placement: 'bottomRight',
        });
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render left column content
     *
     * @function
     * @returns {ReactElement}
     */
    const renderLeftColumn = (): ReactElement | null => {
        return (
            <>
                {!choicesHistory?.configuration ? (
                    <Empty
                        description={t('screens.selector.steps.extensions.configuration.noData')}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                ) : (
                    <>
                        <div className="slider-container">
                            <Slider ref={sliderRef} {...slickSliderSettings}>
                                {choicesHistory.configuration?.imagesUrl
                                    .filter((path, i) => i <= ConfigurationImages.DIMENSIONAL_REV)
                                    .map(
                                        (url: string, i: number) =>
                                            ((i === ConfigurationImages.GENERAL &&
                                                choicesHistory.direction !==
                                                    PossibleDirection.R2L) ||
                                                (i === ConfigurationImages.GENERAL_REV &&
                                                    choicesHistory.direction ===
                                                        PossibleDirection.R2L) ||
                                                (i === ConfigurationImages.DIMENSIONAL &&
                                                    choicesHistory.direction !==
                                                        PossibleDirection.R2L) ||
                                                (i === ConfigurationImages.DIMENSIONAL_REV &&
                                                    choicesHistory.direction ===
                                                        PossibleDirection.R2L)) && (
                                                <div
                                                    key={`${choicesHistory.configuration?.id}_image`}
                                                    className="slick-slide"
                                                >
                                                    <MarinelliImage
                                                        src={[url]}
                                                        alt={`${choicesHistory.configuration?.id}`}
                                                        preview={false}
                                                        size={500}
                                                    />
                                                </div>
                                            ),
                                    )}
                            </Slider>
                        </div>
                        {choicesHistory.configuration.alternativeInstallation && (
                            <>
                                <p className="assembly-alternative">
                                    <a onClick={() => setModalVisible(true)}>
                                        <u>{t('screens.selector.steps.extensions.text.click')}</u>
                                    </a>
                                    {t(
                                        'screens.selector.steps.extensions.text.assembly_alternative',
                                    )}
                                </p>
                                {!!isModalVisible && (
                                    <Modal
                                        centered
                                        visible={isModalVisible}
                                        onCancel={() => setModalVisible(false)}
                                    >
                                        <Card>
                                            <h5>
                                                {t('screens.selector.steps.extensions.modal.title')}
                                            </h5>
                                            <p>
                                                {t(
                                                    'screens.selector.steps.extensions.modal.description',
                                                )}
                                            </p>
                                            <MarinelliImage
                                                src={[
                                                    choicesHistory.configuration?.imagesUrl[
                                                        ConfigurationImages.BROKEN
                                                    ] || '',
                                                ]}
                                            />
                                        </Card>
                                    </Modal>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    /**
     * Render right column content
     *
     * @function
     * @returns {ReactElement}
     */
    const renderRightColumn = (): ReactElement | null => {
        return (
            <>
                {chosenExtensions.length > 0 && (
                    <div className="restart-container">
                        <Button
                            type="link"
                            title={t('props.title.extensions.reset')}
                            onClick={() => setChosenExtensions([])}
                        >
                            {t('screens.selector.steps.extensions.text.reset')}
                            <i className="ri-restart-line" />
                        </Button>
                    </div>
                )}
                {extensionsResponse?.extensions.length === 0 ? (
                    <Empty
                        description={t('screens.selector.steps.extensions.extensions.noData')}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                ) : (
                    <List
                        dataSource={extensionsResponse?.extensions}
                        renderItem={(extension: Extension) => (
                            <List.Item key={extension.id}>
                                <div className="image-container">
                                    <MarinelliImage
                                        alt={`${extension.id} extension image`}
                                        src={[extension.imageUrl]}
                                        preview
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                        checked={
                                            chosenExtensions.filter(
                                                (ex: Extension) => ex.id === extension.id,
                                            ).length > 0
                                        }
                                        disabled={
                                            isFetchingExtensions ||
                                            chosenExtensions.filter(
                                                (ex: Extension) => ex.id === extension.id,
                                            ).length > 0
                                        }
                                        onChange={({ target }: any) => {
                                            if (target.checked) {
                                                setChosenExtensions([
                                                    ...chosenExtensions,
                                                    ...[extension],
                                                ]);
                                            }
                                        }}
                                    >
                                        <div className="text-container">
                                            <h6>{t(`ids.${extension.id}.title`)}</h6>
                                            <p>{t(`ids.${extension.id}.size`)}</p>
                                        </div>
                                        {/* Show the tooltip only if the extension is "Colonna dispensa" */}
                                        {i18n.exists(`ids.${extension.id}.tooltip`) && (
                                            <Tooltip title={t(`ids.${extension.id}.tooltip`)}>
                                                <i
                                                    style={{ cursor: 'default' }}
                                                    className="ri-information-fill"
                                                    onClick={(e) => e.preventDefault()}
                                                />
                                            </Tooltip>
                                        )}
                                    </Checkbox>
                                </div>
                                {chosenExtensions.filter((ex: Extension) => ex.id === extension.id)
                                    .length > 0 && (
                                    <Button
                                        className="uncheck"
                                        type="primary"
                                        shape="circle"
                                        title={t(`props.title.extensions.unselect`, {
                                            extension: t(`ids.${extension.id}`),
                                        })}
                                        onClick={() => {
                                            setChosenExtensions(
                                                chosenExtensions.filter(
                                                    (ex: Extension) => ex.id !== extension.id,
                                                ),
                                            );
                                        }}
                                        icon={<i className="ri-delete-bin-fill" />}
                                    />
                                )}
                            </List.Item>
                        )}
                    />
                )}
            </>
        );
    };

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SelectorStepExtensionsView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SelectorStepExtensionsView] destroy');

        queryClient.resetQueries(QUERIES_KEYS.GET_EXTENSIONS, { exact: true });
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to extensionsResponse in order to show notification alert
     */
    useEffect(() => {
        if (!!extensionsResponse) {
            // Check that a configuration has been returned
            if (!!extensionsResponse.configuration) {
                // Show the warning of the top height over
                if (extensionsResponse.configuration.isTopHeightOver) openTopHeightOverAlert();
                // Show accessory notice
                if (extensionsResponse.configuration.accessories.length > 0) openAccessoriesAlert();
                // Update the configuration in the choicesHistory object
                onUpdateConfiguration(extensionsResponse.configuration);
            } else {
                // Restore last chosen extensions state
                setChosenExtensions(previousChosenExtensions || []);

                // No configuration was returned: show warning
                openNoConfigurationAlert();
            }
        }
    }, [extensionsResponse]);

    /**
     * Hook to chosenExtensions in order to refetch data
     */
    useEffect(() => {
        refetch();
    }, [chosenExtensions]);
    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid={`selector-step-extensions-${side}`}>
            <StepperNavigator
                onPrevious={() => {
                    if (!!stepperProps.onPrevious) {
                        if (side === ExtensionSide.SIDE_ONE) {
                            notification.close(topHeightOverAlertKey);
                            notification.close(accessoriesAlertKey);
                        }
                        stepperProps.onPrevious();
                    }
                }}
                onNext={
                    !!extensionsResponse?.configuration
                        ? () => {
                              if (!!stepperProps.onNext) stepperProps.onNext(chosenExtensions);
                          }
                        : undefined
                }
            />
            <header className="card-header large">
                <h4 className="card-title" title={t('props.title.extensions.title')}>
                    {t(
                        `screens.selector.steps.extensions.${
                            choicesHistory.layout === BaseType.LINEAR
                                ? `${BaseType.LINEAR}.title`
                                : `${BaseType.ANGULAR}.title.${side}`
                        }`,
                    )}
                </h4>
                <p className="card-description">
                    {t('screens.selector.steps.extensions.description')}
                </p>
            </header>
            {isFetchingExtensions ? (
                <div className="loader" style={{ height: 200 }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {isFetchingExtensionsFailed && (
                        <Empty
                            description={t('screens.selector.steps.extensions.noData')}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                    {isFetchingExtensionsSucceded && (
                        <>
                            <LayoutWithSidebar
                                leftContent={renderLeftColumn()}
                                rightContent={renderRightColumn()}
                                reverse
                                sizes={{
                                    left: {
                                        sm: 13,
                                        md: 13,
                                        lg: 12,
                                        xl: 8,
                                    },
                                    right: {
                                        sm: 11,
                                        md: 11,
                                        lg: 10,
                                        xl: 9,
                                    },
                                }}
                                offset={{
                                    right: {
                                        lg: 2,
                                        xl: 7,
                                    },
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default SelectorStepExtensionsView;
