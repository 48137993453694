import { BaseType, KitchenBase } from '@marinelli/shared-types';
import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';

export const basesResponseMock: Array<KitchenBase> = [
    {
        id: '1',
        baseType: BaseType.ANGULAR,
        allowedResellers: ['1,2,3'],
        firstColor: '#0000FF',
        secondColor: '#0000FF',
        thirdColor: '#0000FF',
        imageUrl: [
            'https://via.placeholder.com/273x225?text=Base+image',
            'https://via.placeholder.com/273x225?text=Base+specular+image',
        ],
        sideOne: 100,
        sideTwo: 200,
        maxSideOne: 200,
        maxSideTwo: 300,
    },
    {
        id: '2',
        baseType: BaseType.ANGULAR,
        allowedResellers: ['1,2,3'],
        firstColor: '#0000FF',
        secondColor: '#0000FF',
        thirdColor: '#0000FF',
        imageUrl: [
            'https://via.placeholder.com/273x225?text=Base+image',
            'https://via.placeholder.com/273x225?text=Base+specular+image',
        ],
        sideOne: 100,
        sideTwo: 200,
        maxSideOne: 200,
        maxSideTwo: 300,
    },
    {
        id: '3',
        baseType: BaseType.ANGULAR,
        allowedResellers: ['1,2,3'],
        firstColor: '#0000FF',
        secondColor: '#0000FF',
        thirdColor: '#0000FF',
        imageUrl: [
            'https://via.placeholder.com/273x225?text=Base+image',
            'https://via.placeholder.com/273x225?text=Base+specular+image',
        ],
        sideOne: 100,
        sideTwo: 200,
        maxSideOne: 200,
        maxSideTwo: 300,
    },
    {
        id: '4',
        baseType: BaseType.ANGULAR,
        allowedResellers: ['1,2,3'],
        firstColor: '#0000FF',
        secondColor: '#0000FF',
        thirdColor: '#0000FF',
        imageUrl: [
            'https://via.placeholder.com/273x225?text=Base+image',
            'https://via.placeholder.com/273x225?text=Base+specular+image',
        ],
        sideOne: 100,
        sideTwo: 200,
        maxSideOne: 200,
        maxSideTwo: 300,
    },
    {
        id: '5',
        baseType: BaseType.ANGULAR,
        allowedResellers: ['1,2,3'],
        firstColor: '#0000FF',
        secondColor: '#0000FF',
        thirdColor: '#0000FF',
        imageUrl: [
            'https://via.placeholder.com/273x225?text=Base+image',
            'https://via.placeholder.com/273x225?text=Base+specular+image',
        ],
        sideOne: 100,
        sideTwo: 200,
        maxSideOne: 200,
        maxSideTwo: 300,
    },
    {
        id: '6',
        baseType: BaseType.ANGULAR,
        allowedResellers: ['1,2,3'],
        firstColor: '#0000FF',
        secondColor: '#0000FF',
        thirdColor: '#0000FF',
        imageUrl: [
            'https://via.placeholder.com/273x225?text=Base+image',
            'https://via.placeholder.com/273x225?text=Base+specular+image',
        ],
        sideOne: 100,
        sideTwo: 200,
        maxSideOne: 200,
        maxSideTwo: 300,
    },
];

export const basesPath = `${baseUrl}/configs/bases`;

const basesHandler = rest.get(basesPath, async (req, res, ctx) => res(ctx.json(basesResponseMock)));
export const basesHandlerException = rest.get(basesPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);
export const basesEmptyHandler = rest.get(basesPath, async (req, res, ctx) =>
    res(ctx.status(200), ctx.json([])),
);

export const basesHandlers = [basesHandler];
