export enum ROUTES {
    PRIVACY_POLICY = '/privacy-policy',
    COOKIE_POLICY = '/cookie-policy',
    TERMS_OF_SERVICE = '/terms-of-service',
    INTRO = '/:lang/:idReseller/intro',
    HOME = '/:lang/:idReseller',
}

export enum ROUTES_KEYS {
    HOME = 'selector',
    INTRO = 'intro',
    PRIVACY_POLICY = 'privacy-policy',
    COOKIE_POLICY = 'cookie-policy',
    TERMS_OF_SERVICE = 'terms-of-service',
}
