import React, { ReactElement } from 'react';
import './App.less';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { createBrowserHistory, History } from 'history';
import 'antd/dist/antd.less';
import './styles/style.scss';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserCompatibilityContextProvider } from './modules/browser-compatibility/browserCompatibility';
import 'moment/min/locales';
import { PersistGate } from 'redux-persist/integration/react';
import Main from './Main';
import { persistor, store } from './config/store';
import _environment from './_environment/_environment';
import { NotificationContextProvider } from './providers/notification';
import { ModalContextProvider } from './providers/modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { LanguageContextProvider } from './providers/language/language';
import { SetupContextProvider } from './providers/setup/setup';

// interceptor(store);

ReactGA.initialize(_environment.idGoogleAnalytics, {
    debug: process.env.NODE_ENV === 'development',
    titleCase: false,
});

/**
 * Main React component
 *
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
function App(): ReactElement | null {
    const history: History = createBrowserHistory();

    // Create a client
    const queryClient = new QueryClient();

    console.warn('Hello from new MRN Server');

    return (
        <div className="marinelli-app">
            <QueryClientProvider client={queryClient}>
                <BrowserCompatibilityContextProvider _environment={_environment}>
                    <Router history={history}>
                        <NotificationContextProvider>
                            <LanguageContextProvider>
                                <Provider store={store}>
                                    <PersistGate loading={null} persistor={persistor}>
                                        <ModalContextProvider>
                                            <SetupContextProvider>
                                                <Main history={history} queryClient={queryClient} />
                                            </SetupContextProvider>
                                        </ModalContextProvider>
                                    </PersistGate>
                                </Provider>
                            </LanguageContextProvider>
                        </NotificationContextProvider>
                    </Router>
                </BrowserCompatibilityContextProvider>
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </div>
    );
}

export default App;
