/* eslint-disable no-lonely-if */
import { Alert, InputNumber, Button, Form } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChoicesHistory } from '../../../../../data/interfaces';
import { BaseType, ExtensionSide } from '@marinelli/shared-types';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';
import { basesRegEx } from '../../../../../hooks/useBases';
import _environment from '../../../../../_environment/_environment';
import MarinelliImage from '../../../../../components/layout/image/image';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    side: ExtensionSide;
    stepperProps: StepperProps;
    onSubmit: (dataIgnored: MaxLengthFormData) => void;
    choicesHistory: ChoicesHistory;
    defaultData?: MaxLengthFormData;
}

export interface MaxLengthFormData {
    maxLength: number;
}
export interface MaxLengthStepSizes {
    linear: {
        minLengthAccepted: number;
        maxLengthAccepted: number;
    };
    angular: {
        side1: {
            minLengthAccepted: number;
            maxLengthAccepted: number;
        };
        side2: {
            minLengthAccepted: number;
            maxLengthAccepted: number;
        };
    };
}

/**
 * View representing the kitchen's max-length selection
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {(dataIgnored: MaxLengthFormData) => void} anonymousObject.onSubmit - Called when form is submitted
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {ExtensionSide} anonymousObject.side - Kitchen side
 * @param {MaxLengthFormData} anonymousObject.defaultData - Default form data
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const StepTypesMaxLengthView = ({
    onSubmit,
    side,
    stepperProps,
    defaultData,
    choicesHistory,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const maxLengthStepSizes: MaxLengthStepSizes = {
        linear: {
            minLengthAccepted: 195,
            maxLengthAccepted: 360,
        },
        angular: {
            side1: {
                minLengthAccepted: choicesHistory.base?.sideOne || 250,
                maxLengthAccepted: choicesHistory.base?.maxSideOne || 0,
            },
            side2: {
                minLengthAccepted: choicesHistory.base?.sideTwo || 165,
                maxLengthAccepted: choicesHistory.base?.maxSideTwo || 0,
            },
        },
    };

    /**
     * Returns the value of the minimum allowed length, maximum
     * available length and the translation key of the respective error
     *
     * @function
     * @returns { {val: number; trans: string; max: number} }
     */
    const getSizes = (): { val: number; trans: string; max: number } => {
        let res: { val: number; trans: string; max: number } = {
            val: maxLengthStepSizes.linear.minLengthAccepted,
            max: maxLengthStepSizes.linear.maxLengthAccepted,
            trans: 'linear',
        };

        if (choicesHistory.layout === BaseType.ANGULAR) {
            if (side === ExtensionSide.SIDE_ONE) {
                // Side 1
                res = {
                    val: maxLengthStepSizes.angular.side1.minLengthAccepted,
                    max: maxLengthStepSizes.angular.side1.maxLengthAccepted,
                    trans: 'angular.side_1',
                };
            } else {
                // Side 2
                res = {
                    val: maxLengthStepSizes.angular.side2.minLengthAccepted,
                    max: maxLengthStepSizes.angular.side2.maxLengthAccepted,
                    trans: 'angular.side_2',
                };
            }
        }
        return {
            ...res,
            ...{
                trans: `screens.selector.steps.types.maxLength.${res.trans}.message.warning`,
            },
        };
    };

    const { register, handleSubmit, control, formState, watch, reset } = useForm<MaxLengthFormData>(
        {
            mode: 'onChange',
            defaultValues: defaultData,
            resolver: yupResolver(
                yup.object().shape({
                    maxLength: yup
                        .number()
                        .min(
                            getSizes().val,
                            t(getSizes().trans, {
                                val: getSizes().val,
                                max: getSizes().max,
                            }),
                        )
                        .required(
                            t(getSizes().trans, {
                                val: getSizes().val,
                                max: getSizes().max,
                            }),
                        )
                        .nullable()
                        .typeError(t('forms.errors.type_error_number')),
                }),
            ),
        },
    );

    const watchMaxLength = watch('maxLength');

    const maxLengthAllowed = 500;

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Handle submit form
     *
     * @function
     * @param {MaxLengthFormData} formData - Form data
     * @returns {void}
     */
    const handleSubmitForm = (formData: MaxLengthFormData): void => {
        setFormSubmitting(true);

        onSubmit(formData);

        reset();
        setFormSubmitting(false);
    };

    /**
     * Returns the image to use as main asset
     *
     * @function
     * @returns {string}
     */
    const getImage = (): string => {
        let res = `${_environment.api.baseURL}/static/base_types/`;
        if (choicesHistory.layout === BaseType.LINEAR) {
            res += BaseType.LINEAR;
        } else {
            res += `${choicesHistory.base?.id.replace(basesRegEx, '$1$2$3$4$5$6$7!$9!')}_side${
                side === ExtensionSide.SIDE_ONE ? '1' : '2'
            }`;
        }

        res += '.png';

        return res;
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Render the maximum length message of the kitchens if necessary
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderMaxLengthInfoMessage = (): ReactElement | null => {
        let canShow = false;

        if (choicesHistory.layout === BaseType.LINEAR) {
            // Linear kitchen layout
            if (watchMaxLength > maxLengthStepSizes.linear.maxLengthAccepted) canShow = true;
        } else {
            // Angular kitchen layout
            if (side === ExtensionSide.SIDE_ONE) {
                // Side 1
                if (watchMaxLength > maxLengthStepSizes.angular.side1.maxLengthAccepted)
                    canShow = true;
            } else {
                // Side 2

                if (watchMaxLength > maxLengthStepSizes.angular.side2.maxLengthAccepted)
                    canShow = true;
            }
        }

        if (!canShow) return null;

        let maximumLengthAvailable: number = maxLengthStepSizes.linear.maxLengthAccepted;

        if (choicesHistory.layout === BaseType.ANGULAR) {
            if (side === ExtensionSide.SIDE_ONE) {
                // Side 1
                maximumLengthAvailable = maxLengthStepSizes.angular.side1.maxLengthAccepted;
            } else {
                // Side 2
                maximumLengthAvailable = maxLengthStepSizes.angular.side2.maxLengthAccepted;
            }
        }

        return (
            <span
                role="alert"
                title={t('props.title.maxLength.maxAlert')}
                className="note-container"
            >
                <h6>{t('screens.selector.steps.types.maxLength.note_title')}</h6>
                {t(
                    `screens.selector.steps.types.maxLength.${
                        choicesHistory.layout === BaseType.LINEAR
                            ? BaseType.LINEAR
                            : `${BaseType.ANGULAR}.side_${
                                  side === ExtensionSide.SIDE_ONE ? '1' : '2'
                              }`
                    }.message.note`,
                    { val: maximumLengthAvailable },
                )}
            </span>
        );
    };
    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[StepTypesMaxLengthView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[StepTypesMaxLengthView] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return !!choicesHistory?.layout ? (
        <div
            data-testid={`selector-step-wall-length-${
                choicesHistory.layout === BaseType.LINEAR || side === ExtensionSide.SIDE_ONE
                    ? '1'
                    : '2'
            }`}
        >
            <StepperNavigator onPrevious={stepperProps.onPrevious} onNext={stepperProps.onNext} />
            <header className="card-header large">
                <h4 className="card-title">
                    {t(
                        `screens.selector.steps.types.maxLength.${
                            choicesHistory.layout === BaseType.LINEAR
                                ? BaseType.LINEAR
                                : `${BaseType.ANGULAR}.side_${
                                      side === ExtensionSide.SIDE_ONE ? '1' : '2'
                                  }`
                        }.title`,
                    )}{' '}
                </h4>
                <p className="card-description">
                    {t('screens.selector.steps.types.maxLength.description')}
                </p>
            </header>
            <div className="max-length-container">
                <div className="left-content">
                    <Form layout="vertical" size="large" onFinish={handleSubmit(handleSubmitForm)}>
                        <div className="input-container">
                            <Form.Item validateStatus={!!formState.errors.maxLength ? 'error' : ''}>
                                <Controller
                                    render={({ onChange, value }) => (
                                        <InputNumber
                                            title={t('props.title.maxLength.maxNumber')}
                                            placeholder={t(
                                                'screens.selector.steps.types.maxLength.inputNumber_placeholder',
                                            )}
                                            ref={register}
                                            disabled={isFormSubmitting}
                                            autoFocus
                                            onChange={(val) => {
                                                onChange(
                                                    val <= maxLengthAllowed
                                                        ? val
                                                        : maxLengthAllowed,
                                                );
                                            }}
                                            value={value}
                                        />
                                    )}
                                    name="maxLength"
                                    control={control}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    title={t('props.title.maxLength.form.button')}
                                    type="primary"
                                    disabled={
                                        !formState.isValid || watchMaxLength > maxLengthAllowed
                                    }
                                    loading={isFormSubmitting}
                                    htmlType="submit"
                                    icon={<i className="ri-arrow-right-s-line" />}
                                />
                            </Form.Item>
                        </div>
                        <div className="message">
                            {renderMaxLengthInfoMessage()}
                            {formState.errors.maxLength?.type === 'min' && (
                                <span role="alert" title={t('props.title.maxLength.minAlert')}>
                                    <Alert
                                        message={formState.errors.maxLength.message}
                                        type="error"
                                        showIcon
                                        icon={<i className="ri-alert-fill" />}
                                    />
                                </span>
                            )}
                            {formState.isValid && (
                                <span role="alert" title={t('props.title.maxLength.successNote')}>
                                    <Alert
                                        message={t(
                                            'screens.selector.steps.types.maxLength.success_message',
                                        )}
                                        type="success"
                                        showIcon
                                        icon={<i className="ri-checkbox-circle-fill" />}
                                    />
                                </span>
                            )}
                        </div>
                    </Form>
                </div>

                <div className="image">
                    <MarinelliImage
                        alt={`${choicesHistory.layout} kitchen type`}
                        size={500}
                        src={[getImage()]}
                    />
                </div>
            </div>
        </div>
    ) : null;
};

export default StepTypesMaxLengthView;
