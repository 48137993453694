import React, { useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionCardI } from '../components/cards/actionCard/actionCard';
import {
    BaseType,
    ConfigurationImages,
    HandleType,
    Hob,
    KitchenBase,
    OvenType,
    PossibleDirection,
} from '@marinelli/shared-types';
import _environment from '../_environment/_environment';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------
interface useBasesData {
    mapBaseIntoActionCard(
        base: KitchenBase,
        onSelect: (selectedItemIdIgnored: any) => void,
        direction: PossibleDirection,
    ): ActionCardI;
    getBaseImageUrl(base: KitchenBase, direction: PossibleDirection): string;
    mapBaseTypesIntoActionCard(
        type: BaseType,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI;
    mapBaseHandleTypeIntoActionCard(
        type: HandleType,
        layout: BaseType,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI;
    mapBaseOvenTypeIntoActionCard(
        handle: HandleType,
        type: OvenType,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI;
    mapHobTypeIntoActionCard(hob: Hob, onSelect: (selectedItemIdIgnored: any) => void): ActionCardI;
    getAccessoriesTranslationKey(id: string): string;
    getBasesImagePath(id: string): string;
}

export const basesRegEx = /(P)(UR|SE)(\d\d\d)(LN|AG)(\d\d\d)(C|B)(\d\d)(\w)(\w)(\w*)/g;
export const accessoriesRegEx = /(F|P)(UR|SE|XX)(PEAN|FTC|PEN)(\d\d\d\d\d)(\d*)(\d\d\d)(X|D|S)/g;

/**
 * Custom hook
 *
 * @function
 * @class
 * @category Utils
 * @subcategory Hooks
 */
function useBases(): useBasesData {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Returns the url of the base image
     *
     * @param {KitchenBase} base - KitchenBase to map
     * @param {PossibleDirection} direction - Determine if the image is in mirror mode
     * @function
     * @returns {string}
     */
    const getBaseImageUrl = (
        base: KitchenBase,
        direction: PossibleDirection = PossibleDirection.L2R,
    ): string => {
        return direction === PossibleDirection.L2R
            ? base.imageUrl[ConfigurationImages.GENERAL]
            : base.imageUrl[ConfigurationImages.GENERAL_REV] ??
                  base.imageUrl[ConfigurationImages.GENERAL];
    };

    /**
     * Maps a base object into an action card interface
     *
     * @param {KitchenBase} base - KitchenBase to map
     * @param {(selectedItemIgnored: ActionCardI) => void} onSelect - Callback function
     * @param {PossibleDirection} direction - Determine if the image is in mirror mode
     * @function
     * @returns {ActionCardI}
     */
    const mapBaseIntoActionCard = (
        base: KitchenBase,
        onSelect: (selectedItemIdIgnored: any) => void,
        direction: PossibleDirection = PossibleDirection.L2R,
    ): ActionCardI => {
        let bottomText: string | ReactElement;
        if (base.baseType === BaseType.LINEAR) {
            bottomText = `${base.sideOne} cm`;
        } else {
            bottomText = (
                <>
                    <p>
                        {t(
                            `screens.selector.steps.base.angular.${base.id.replace(
                                basesRegEx,
                                '$1**$3$4***$6$7***',
                            )}.side_1`,
                        )}
                    </p>
                    <p>
                        {t(
                            `screens.selector.steps.base.angular.${base.id.replace(
                                basesRegEx,
                                '$1**$3$4***$6$7***',
                            )}.side_2`,
                        )}
                    </p>
                </>
            );
        }

        return {
            id: base.id,
            imageUrl: getBaseImageUrl(base, direction),
            onSelect,
            zoomable: true,
            bottomText,
            defaultImagePath: '/assets/images/undraw/base.svg',
            buttonTitle: t('props.title.bases.select'),
        };
    };

    /**
     * Get the translation key name starting from accessory id
     *
     * @function
     * @param {string} id - Accessory id
     * @returns {string}
     */
    const getAccessoriesTranslationKey = (id: string): string => {
        return id.replace(accessoriesRegEx, '$1**$3$4$5****');
    };

    /**
     * Get image path of the selected base without the reseller letter.
     *
     * @function
     * @param {string} id - selected base id
     * @returns {string}
     */
    const getBasesImagePath = (id: string): string => {
        return id.replace(basesRegEx, '$1$2$3$4$5$6$7$8$9!');
    };

    /**
     * Maps the base type object into an action card interface
     *
     * @param {BaseType} type - Type to map
     * @param {(selectedItemIgnored: ActionCardI) => void} onSelect - Callback function
     * @function
     * @returns {ActionCardI}
     */
    const mapBaseTypesIntoActionCard = (
        type: BaseType,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI => {
        return {
            id: type,
            title: t(`screens.selector.steps.types.kitchenLayout.card.${type}.title`),
            description: t(`screens.selector.steps.types.kitchenLayout.card.${type}.description`),
            imageUrl: `${_environment.api.baseURL}/static/base_types/${type}.png`,
            onSelect,
            buttonTitle: t(`components.cards.title`, {
                title: t(`screens.selector.steps.types.kitchenLayout.card.${type}.title`),
            }),
            defaultImagePath: 'https://via.placeholder.com/273x225',
        };
    };

    /**
     * Maps the handle type into an action card interface
     *
     * @param {HandleType} type - Type to map
     * @param {BaseType} layout - Kitchen base layout
     * @param {(selectedItemIgnored: ActionCardI) => void} onSelect - Callback function
     * @function
     * @returns {ActionCardI}
     */
    const mapBaseHandleTypeIntoActionCard = (
        type: HandleType,
        layout: BaseType,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI => {
        return {
            id: type,
            title: t(`screens.selector.steps.structure.handle.card.${type}.title`),
            description: t(`screens.selector.steps.structure.handle.card.${type}.description`),
            imageUrl: `${_environment.api.baseURL}/static/handle_types/${layout}_${type}.png`,
            onSelect,
            buttonTitle: t(`components.cards.title`, {
                title: t(`screens.selector.steps.structure.handle.card.${type}.title`),
            }),
            defaultImagePath: 'https://via.placeholder.com/273x225',
        };
    };

    /**
     * Maps the oven positions into an action card interface
     *
     * @param {HandleType} handle - Type to map
     * @param {OvenType} type - Type to map
     * @param {(selectedItemIgnored: ActionCardI) => void} onSelect - Callback function
     * @function
     * @returns {ActionCardI}
     */
    const mapBaseOvenTypeIntoActionCard = (
        handle: HandleType,
        type: OvenType,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI => {
        return {
            id: type,
            title: t(`screens.selector.steps.structure.oven.card.${type}.title`),
            description: t(`screens.selector.steps.structure.oven.card.${type}.description`),
            imageUrl: `${_environment.api.baseURL}/static/oven_positions/${type}_${handle}.png`,
            onSelect,
            buttonTitle: t(`components.cards.title`, {
                title: t(`screens.selector.steps.structure.oven.card.${type}.title`),
            }),
            defaultImagePath: 'https://via.placeholder.com/273x225',
        };
    };

    /**
     * Maps the hob types into an action card interface
     *
     * @param {Hob} hob - Hob object to map
     * @param {(selectedItemIgnored: ActionCardI) => void} onSelect - Callback function
     * @function
     * @returns {ActionCardI}
     */
    const mapHobTypeIntoActionCard = (
        hob: Hob,
        onSelect: (selectedItemIdIgnored: any) => void,
    ): ActionCardI => {
        return {
            id: hob.id,
            title: t(`screens.selector.steps.accessories.hob.${hob.id}.title`),
            description: t(`screens.selector.steps.accessories.hob.${hob.id}.description`),
            onSelect,
            buttonTitle: t(`components.cards.title`, {
                title: t(`screens.selector.steps.accessories.hob.${hob.id}.title`),
            }),
            imageUrl: `${hob.imageUrl}`,
            defaultImagePath: 'https://via.placeholder.com/273x225',
        };
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[useBases] init');
    };
    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[useBases] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return {
        mapBaseIntoActionCard,
        getBaseImageUrl,
        mapBaseTypesIntoActionCard,
        mapBaseHandleTypeIntoActionCard,
        mapBaseOvenTypeIntoActionCard,
        mapHobTypeIntoActionCard,
        getAccessoriesTranslationKey,
        getBasesImagePath,
    };
}

export default useBases;
