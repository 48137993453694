import React, { ReactElement, useEffect } from 'react';
import { Button } from 'antd';
import { ChoicesHistory } from '../../data/interfaces';
import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


export interface StepperProps {
    onPrevious?: (newHistory?: ChoicesHistory, goBack?: boolean) => void;
    onNext?: (data?: any) => void;
    labels?: {
        prev?: string;
        next?: string;
    };
    icons?: {
        prev?: string;
        next?: string;
    };
}

const StepperNavigator = ({
    onPrevious,
    onNext,
    labels,
    icons,
    ...restProps
}: StepperProps): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();
    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[StepperNavigator] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[StepperNavigator] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <div className="stepper-navigator">
            {!!onPrevious && (
                <Button
                    title={t('props.title.stepperNavigator.back')}
                    size="large"
                    onClick={() => {
                        if (!!onPrevious) onPrevious();
                    }}
                    type="default"
                    icon={<i className={`${icons?.prev || 'ri-arrow-left-s-line'}`} />}
                >
                    {labels?.prev || t('components.stepperNavigator.buttons.back')}
                    {/* TODO: TRANSLATE ME */}
                </Button>
            )}
            {!!onNext && (
                <Button
                    title={t('props.title.stepperNavigator.next')}
                    size="large"
                    onClick={onNext}
                    type="primary"
                >
                    {labels?.next || t('components.stepperNavigator.buttons.next')}
                    {/* TODO: TRANSLATE ME */}
                    <i className={`${icons?.next || 'ri-arrow-right-s-line'}`} />
                </Button>
            )}
        </div>
    );
};
export default StepperNavigator;
