import { useContext, useEffect } from 'react';
import { QueryObserverOptions } from 'react-query/types/core/types';
import { INotificationContext, NotificationContext } from '../providers/notification';
import * as Sentry from '@sentry/react';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------
interface useQueriesData {
    getBaseQueryParams(): QueryObserverOptions;
    getFetchRequestQueryParams(overrides?: QueryObserverOptions): QueryObserverOptions;
    getUpsertRequestQueryParams(overrides?: QueryObserverOptions): QueryObserverOptions;
}

/**
 * Custom hook
 *
 * @function
 * @class
 * @category Utils
 * @subcategory Hooks
 */
function useQueries(): useQueriesData {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to notification manager hook that you may use to open and close notifications.
    const { openErrorNotification, errorNotificationWhitelist } =
        useContext<INotificationContext>(NotificationContext);

    /**
     * COMMENT ME
     *
     * @param {any} failureCount - The failure count for the query. Incremented every time the query fails. Reset to 0 when the query succeeds.
     * @param {unknown | null} error - The error object for the query, if an error was thrown.
     * @function
     * @returns {boolean}
     */
    const getRetryCallback = (failureCount: number, error: unknown | null): boolean => {
        return !(failureCount > 1 || errorNotificationWhitelist().includes(`${error}`));
    };

    /**
     * COMMENT ME
     *
     * @param {unknown | null} error - The error object for the query, if an error was thrown.
     * @function
     * @returns {void}
     */
    const handleErrorLog = (error: unknown): void => {
        console.trace('[Handled error]', error);

        if (!errorNotificationWhitelist().includes(`${error}`)) {
            Sentry.captureException(error, {
                user: undefined,
            });
        }

        // Print error to the user
        openErrorNotification('error', {
            // message: t('errors.general.title'),
            message: '',
            description: `${error}`,
        });
    };

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * COMMENT ME
     *
     * @function
     * @returns {QueryObserverOptions}
     */
    const getBaseQueryParams = (): QueryObserverOptions => {
        return {
            retry: getRetryCallback,
            refetchOnWindowFocus: false,
            onError: (error: unknown) => {
                handleErrorLog(error);
            },
        };
    };

    /**
     * COMMENT ME
     *
     * @param {QueryObserverOptions} overrides - COMMENT ME
     * @function
     * @returns {QueryObserverOptions}
     */
    const getFetchRequestQueryParams = (overrides?: QueryObserverOptions): QueryObserverOptions => {
        return {
            ...getBaseQueryParams(),
            ...{
                /* Time in ms after which data are considered obsolete and therefore re-fetched */
                // staleTime: 300000,
            },
            ...overrides,
        };
    };

    /**
     * COMMENT ME
     *
     * @param {QueryObserverOptions} overrides - COMMENT ME
     * @function
     * @returns {QueryObserverOptions}
     */
    const getUpsertRequestQueryParams = (
        overrides?: QueryObserverOptions,
    ): QueryObserverOptions => {
        return {
            ...getBaseQueryParams(),
            ...{
                /* Time in ms after which data are considered obsolete and therefore re-fetched */
                staleTime: Infinity,
            },
            ...overrides,
        };
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[useQueries] init');
    };
    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[useQueries] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return {
        getBaseQueryParams,
        getFetchRequestQueryParams,
        getUpsertRequestQueryParams,
    };
}

export default useQueries;
