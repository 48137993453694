import React, { ReactElement, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { COOKIES } from '../../../modules/common/data/enums';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    onClose: any;
}

const CookiePolicyContent = ({ onAccept }: any): ReactElement | null => {
    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const [isTruncated, setTruncated] = useState<boolean>(true);

    return (
        <>
            {isTruncated
                ? `${t('components.cookieBar.content').split('.')[0]}...`
                : t('components.cookieBar.content')}
            <footer>
                <Button onClick={() => setTruncated(!isTruncated)} type="link">
                    {isTruncated
                        ? t('components.cookieBar.showMore')
                        : t('components.cookieBar.showLess')}
                </Button>
                <Button onClick={onAccept} type="primary">
                    {t('components.cookieBar.accept')}
                </Button>
            </footer>
        </>
    );
};

/**
 * Component that takes care of rendering the cookie policy banner
 * to be accepted by the user.
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {any} anonymousObject.onClose - Called when the cookie bar is closed
 * @component
 * @category Components
 * @subcategory Fragment
 * @returns {ReactElement | null}
 */
const CookieBar = ({ onClose, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Sets the cookie that prevents the cookie bar from reappearing
     *
     * @function
     */
    const setCookie = (): void => {
        // Expire in 5 years
        Cookies.set(COOKIES.COOKIES_ACCEPTED, 'true', { path: '', expires: 365 * 5 });
        message.destroy();
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------

    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[CookieBar] init');

        message.info({
            duration: 0,
            onClose,
            className: 'cookie-bar',
            content: <CookiePolicyContent onAccept={setCookie} />,
        });
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[CookieBar] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return null;
};
export default CookieBar;
