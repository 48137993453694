import { Accessory, PriceInformations } from '@marinelli/shared-types';
import { Button, Card, Modal, notification } from 'antd';
import download from 'downloadjs';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StepperProps } from '../../../../../components/stepperNavigator/stepperNavigator';
import { ChoicesHistory } from '../../../../../data/interfaces';
import useQueries from '../../../../../hooks/useQueries';
import { createOrder } from '../../../../../redux/orders/action';
import { ContextResellerState } from '../../../../../redux/resellers/contextReseller/reducer';
import _environment from '../../../../../_environment/_environment';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    choicesHistory: ChoicesHistory;
    pricesInformations: Array<PriceInformations>;
    stepperProps: StepperProps;
}

/**
 * View representing the Welcome Page of the selector
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @param {PriceInformations} anonymousObject.pricesInformations - Informations about prices
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const Footer = ({
    choicesHistory,
    pricesInformations,
    stepperProps,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [pdfBlobData, setPdfBlobData] = useState<any>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const { lang, idReseller }: any = useParams();

    // Get data from the authReducer store state
    const { reseller } = useSelector(
        ({ contextResellerReducer }: { contextResellerReducer: ContextResellerState }) =>
            contextResellerReducer,
    );

    const { getUpsertRequestQueryParams }: any = useQueries();

    const order = {
        resellerId: `${idReseller}`,
        lang: `${lang}`,
        chosenHobId: choicesHistory.hob?.id,
        chosenHobPrice: pricesInformations[1].price,
        configId: choicesHistory.configuration?.id || '',
        configPrice: pricesInformations[0].price,
        configResellerCode: pricesInformations[0].internalResellerCode,
        chosenAccessories:
            choicesHistory.configuration?.colored_accessories?.map((a: Accessory, i: number) => {
                return {
                    id: a.id,
                    internalResellerCode: pricesInformations[i + 2].internalResellerCode,
                    price: pricesInformations[i + 2].price,
                };
            }) || [],
    };

    // Subscription to an upsert query
    const { mutate: triggerCreateOrder, isLoading: isCreatingOrder } = useMutation(
        (print: boolean) => createOrder(order),
        {
            ...getUpsertRequestQueryParams(),
            ...{
                onSuccess: (data: any, print: boolean) => {
                    if (print) {
                        printPdf(data.content);
                    } else {
                        download(data.content, data.fileName, data.contentType);
                    }
                    setPdfBlobData(data);
                    setModalVisible(false);
                },
            },
        },
    );

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Opens pdf file in a new tab
     *
     * @param {any} content - Pdf content
     * @function
     */
    const printPdf = (content: any): void => {
        const url = URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
    };

    // Creates a new order on the server and sends the response to the
    // embedder site (we assume to be in a iframe) via 'window.postMessage'.
    const sendOrderToEmbedder = useCallback(async () => {
        setLoading(true);
        try {
            const res = await fetch(`${_environment.api.baseURL}/orders/json`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(order),
            });
            if (res.status !== 201) throw 'error-order-creation';
            top?.postMessage(await res.json(), '*');
        } catch (err) {
            notification.error({
                message: '',
                description: t('errors.general.description'),
            });
        } finally {
            setLoading(false);
        }
    }, [order]);

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component

        console.log('[Footer] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Footer] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <footer>
            <div className="summary-footer">
                <Button
                    size="large"
                    type="default"
                    // @ts-ignore
                    onClick={stepperProps.onPrevious}
                    icon={<i className="ri-arrow-left-s-line" />}
                    title={t('props.title.stepperNavigator.back')}
                >
                    {t('screens.summary.buttons.back')}
                </Button>

                {reseller?.allow_message_to_host && (
                    <Button
                        size="large"
                        type="primary"
                        shape="circle"
                        disabled={isLoading}
                        onClick={sendOrderToEmbedder}
                    >
                        <i className="ri-shopping-cart-2-line" />
                    </Button>
                )}

                <Button
                    size="large"
                    type="primary"
                    onClick={() => setModalVisible(true)}
                    title={t('props.title.stepperNavigator.next')}
                >
                    {t('screens.summary.buttons.pdf')}
                </Button>
            </div>
            <Modal
                className="summary-modal"
                centered
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                width={446}
            >
                <Card>
                    <h5>{t('screens.summary.modal.title')}</h5>
                    <p>{t('screens.summary.modal.description')}</p>
                    <div className="modal-buttons">
                        <div className="modal-button-container">
                            <Button
                                disabled={isCreatingOrder}
                                onClick={() => {
                                    if (!pdfBlobData) {
                                        triggerCreateOrder(false);
                                    } else {
                                        setModalVisible(false);
                                        download(
                                            pdfBlobData.content,
                                            pdfBlobData.fileName,
                                            pdfBlobData.contentType,
                                        );
                                    }
                                }}
                                type="primary"
                                icon={<i className="ri-save-fill" />}
                            />
                            <p>{t('screens.summary.modal.save')}</p>
                        </div>
                        <div className="modal-button-container">
                            <Button
                                disabled={isCreatingOrder}
                                onClick={() => {
                                    if (!pdfBlobData) {
                                        triggerCreateOrder(true);
                                    } else {
                                        setModalVisible(false);
                                        printPdf(pdfBlobData.content);
                                    }
                                }}
                                type="primary"
                                icon={<i className="ri-printer-fill" />}
                            />
                            <p>{t('screens.summary.modal.print')}</p>
                        </div>
                    </div>
                </Card>
            </Modal>
        </footer>
    );
};
export default Footer;
