import { HandleType, OvenType } from '@marinelli/shared-types';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ActionCard from '../../../../../components/cards/actionCard/actionCard';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';
import { ChoicesHistory } from '../../../../../data/interfaces';
import useBases from '../../../../../hooks/useBases';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    onSelect: (choosenData: OvenType) => void;
    choicesHistory: ChoicesHistory;
    stepperProps: StepperProps;
}
/**
 * View representing the structural options to add oven type
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {(dataIgnored: STRUCTURES_TYPES) => void} anonymousObject.onSelect - Called when an element is selected
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const OvenPositionView = ({
    onSelect,
    choicesHistory,
    stepperProps,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const ovenPositions: Array<OvenType> = [OvenType.UNDER, OvenType.COLUMN];

    // Access to bases manager hook.
    const { mapBaseOvenTypeIntoActionCard } = useBases();

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[OvenPositionView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[OvenPositionView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-step-oven-position">
            <StepperNavigator onPrevious={stepperProps.onPrevious} onNext={stepperProps.onNext} />
            <header className="card-header large">
                <h4 className="card-title"> {t(`screens.selector.steps.structure.oven.title`)} </h4>
            </header>
            <div className="card-list">
                {ovenPositions.map((oven: OvenType) => (
                    <ActionCard
                        key={oven}
                        actionCard={mapBaseOvenTypeIntoActionCard(
                            choicesHistory.handle || HandleType.INTERNAL,
                            oven,
                            (choosenOven: OvenType) => {
                                onSelect(choosenOven);
                            },
                        )}
                    />
                ))}
            </div>
        </div>
    );
};
export default OvenPositionView;
