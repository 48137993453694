import { Extension } from '@marinelli/shared-types';
import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';
import { ExtensionsResponse } from '../../data/interfaces';
import { extensions, configuration } from '../data';

export const extensionsPath = `${baseUrl}/configs/extensions`;

export const extensionsResponseMock: ExtensionsResponse = {
    extensions,
    configuration,
};

// Simulates a configuration with extensions to be added
const extensionsHandler = rest.get(extensionsPath, async (req, res, ctx) =>
    res(ctx.json(extensionsResponseMock)),
);

// Returns an error exception
export const extensionsHandlerException = rest.get(extensionsPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);

/* Simulates a response that found no available configuration
(When due to a DB-side error, no configuration is found) */
export const extensionsNoConfigurationHandler = rest.get(extensionsPath, async (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ extensions })),
);

/* Simulates a response with a configuration with no extension to add
(When there are no extensions available to add to the configuration) */
export const extensionsNoAdditionalExtensionsHandler = rest.get(
    extensionsPath,
    async (req, res, ctx) =>
        res(
            ctx.status(200),
            ctx.json({
                extensions: [],
                configuration,
            }),
        ),
);

/* Simulates a response with a configuration with no extensions added in side 1 and with side 2 extensions not yet defined
(When the reset button was pressed in the choice of extensions on the first side) */
export const extensionsEmptySide1ExtensionsHandler = rest.get(
    extensionsPath,
    async (req, res, ctx) =>
        res(
            ctx.status(200),
            ctx.json({
                extensions,
                configuration: {
                    ...configuration,
                    ...{
                        sideOneExtensions: [],
                        sideTwoExtensions: undefined,
                    },
                },
            }),
        ),
);

/* Simulates a response with a configuration with extensions added on side 1 and without extensions added on side 2
(When the reset button was pressed in the selection of the second side extensions) */
export const extensionsEmptySide2ExtensionsHandler = rest.get(
    extensionsPath,
    async (req, res, ctx) =>
        res(
            ctx.status(200),
            ctx.json({
                extensions,
                configuration: {
                    ...configuration,
                    ...{
                        sideTwoExtensions: [],
                    },
                },
            }),
        ),
);

/* Simulates a response with a configuration with all extensions added except the first
(When all available extensions are selected and the first one is deselected) */
export const extensionsNoFirstExtensionsHandler = rest.get(extensionsPath, async (req, res, ctx) =>
    res(
        ctx.status(200),
        ctx.json({
            extensions,
            configuration: {
                ...configuration,
                ...{
                    sideTwoExtensions: (
                        extensionsResponseMock.configuration?.sideTwoExtensions || []
                    ).filter((ex: Extension) => ex.id !== extensionsResponseMock.extensions[0].id),
                },
            },
        }),
    ),
);

/* Simulates a response with a configuration with only first extension added
(When all available extensions are deselected and the first one is selected) */
export const extensionsOnlyFirstExtensionsHandler = rest.get(
    extensionsPath,
    async (req, res, ctx) =>
        res(
            ctx.status(200),
            ctx.json({
                extensions,
                configuration: {
                    ...configuration,
                    ...{
                        sideTwoExtensions: (extensionsResponseMock.configuration
                            ?.sideTwoExtensions || [])[0],
                    },
                },
            }),
        ),
);

export const extensionsHandlers = [extensionsHandler];
