import _ from 'lodash';
/**
 * Cleans an object of unnecessary properties (undefined, null, etc). Can be used for example to send data to the WebService
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @param {Object} obj - Any type of object
 * @param {boolean?} removeZeros - Determines whether to also remove keys that have 0 as a value
 */
export const pruneEmpty = (obj: any, removeZeros: boolean = false): any =>
    (function prune(current) {
        _.forOwn(current, (value, key) => {
            if (
                _.isUndefined(value) ||
                _.isNull(value) ||
                _.isNaN(value) ||
                (_.isString(value) && _.isEmpty(value)) ||
                (_.isNumber(value) && removeZeros && value === 0) ||
                (_.isObject(value) && !_.isArray(value) && _.isEmpty(prune(value)))
            ) {
                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) _.pull(current, undefined);

        return !_.isEmpty(current) ? current : undefined;
    })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead

/**
 * Receives as input a numeric index and returns the corresponding letter of the alphabet
 *
 * @function
 * @class
 * @category Utils
 * @subcategory General
 * @param {number} i - Numeric index
 * @returns {string} letter - Corresponding letter of the alphabet
 */
export const mapIndexToAlphabet = (i: number): string => {
    const alphabet = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ];
    return alphabet[i];
};
