/**
 * Cookies variables
 *
 * @enum {string}
 * @alias Cookies
 * @category Enum
 */
export enum COOKIES {
    COOKIES_ACCEPTED = 'cookies_accepted',
    INTRO = 'intro',
}

/**
 * COMMENT ME
 *
 * @enum {string}
 * @alias Queries Keys
 * @category Enum
 */
export enum QUERIES_KEYS {
    GET_RESELLER = 'getReseller',
    VALIDATE_IMAGE = 'validateImage',
    GET_BASES = 'getBases',
    GET_PRICES = 'getPrices',
    GET_EXTENSIONS = 'getExtensions',
    GET_COLORS = 'getColors',
    CREATE_ORDER = 'createOrder',
    GET_CONFIGURATION = 'getConfiguration',
    GET_TRANSLATIONS = 'getTranslations',
}
