import { Reseller } from '@marinelli/shared-types';
import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';

export const resellersResponseMock: Reseller = {
    id: '123456',
    name: 'Reseller name',
    logoUrl: 'https://via.placeholder.com/500x90?text=RESELLER+LOGO',
    introBgUrl: 'https://via.placeholder.com/500x90?text=RESELLER+LOGO',
    websiteUrl: 'https://placeholder.com',
    color: '#52BB58',
    countries: ['it'],
    show_mrn_logos: true,
    allow_message_to_host: true,
    is_embedded_in_iframe: false,
};
export const resellersPath = `${baseUrl}/env/resellers`;

const resellersHandler = rest.get(resellersPath, async (req, res, ctx) =>
    res(ctx.json(resellersResponseMock)),
);
export const resellersHandlerException = rest.get(resellersPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);

export const resellersHandlers = [resellersHandler];
