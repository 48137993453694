import { Image } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContextResellerState } from '../../../../../redux/resellers/contextReseller/reducer';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {}

/**
 * View representing the Welcome Page of the selector
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const Header = ({ ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------
    // Get data from the authReducer store state
    const { reseller } = useSelector(
        ({ contextResellerReducer }: { contextResellerReducer: ContextResellerState }) =>
            contextResellerReducer,
    );

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s

    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component

        console.log('[Header] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Header] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <header>
            {!reseller?.is_embedded_in_iframe && (
                <Image preview={false} src={`${reseller?.logoUrl}`} />
            )}
            {reseller?.show_mrn_logos ? (
                <div className="secondary-logo">
                    <Image src="/assets/images/resources/logo/mimo.png" preview={false} />
                    <Image src="/assets/images/resources/logo/marinelli.png" preview={false} />
                </div>
            ) : undefined}
        </header>
    );
};
export default Header;
