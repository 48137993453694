import { IGetExtensions } from '@marinelli/shared-types';
import { useQuery, UseQueryResult } from 'react-query';
import { ExtensionsResponse } from '../../../data/interfaces';
import { QUERIES_KEYS } from '../../../modules/common/data/enums';
import { getExtensions } from '../../../redux/extensions/action';
import useQueries from '../../useQueries';

/**
 * @param {IGetExtensions} data - Input data
 * @param {boolean} isEnabled - Defines whether the query should be executed immediately
 * @returns {UseQueryResult<ExtensionsResponse, any>}
 */
export const useGetExtensionsQuery = (
    data: IGetExtensions,
    isEnabled: boolean = false,
): UseQueryResult<ExtensionsResponse, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_EXTENSIONS],
        async () => {
            return getExtensions(data);
        },
        getFetchRequestQueryParams({
            enabled: isEnabled,
            retry: !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development',
        }),
    );
};
