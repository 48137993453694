import { useQuery } from 'react-query';
import { QUERIES_KEYS } from '../../../modules/common/data/enums';
import { getTranlsationsFiles } from '../../../redux/translations/action';
import useQueries from '../../useQueries';

/**
 * @param {string} lang - Language to fetch
 * @param {boolean} enabled - Determines whether it is possible to execute the request
 */

export const useGetLanguagesQuery = (lang: string, enabled: boolean) => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [
            QUERIES_KEYS.GET_TRANSLATIONS,
            {
                lang,
            },
        ],
        async () => {
            return getTranlsationsFiles(lang);
        },
        getFetchRequestQueryParams({
            enabled,
            onError: undefined,
            retry: !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development',
        }),
    );
};
