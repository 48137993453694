import { PriceInformations } from '@marinelli/shared-types';
import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';

export const pricesResponseMock: Array<PriceInformations> = [
    {
        productId: '0',
        price: '100,30 €',
        internalResellerCode: '123456789',
    },
    {
        productId: '1',
        price: '50 €',
        internalResellerCode: '456123789',
    },
    {
        productId: '2',
        price: '11,64 €',
        internalResellerCode: '789132465',
    },
    {
        productId: '30',
        price: '534,23 €',
        internalResellerCode: '798456132',
    },
];

export const pricesPath = `${baseUrl}/prices`;

const pricesHandler = rest.get(pricesPath, async (req, res, ctx) =>
    res(ctx.json(pricesResponseMock)),
);
export const pricesHandlerException = rest.get(pricesPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);
export const pricesEmptyHandler = rest.get(pricesPath, async (req, res, ctx) =>
    res(ctx.status(200), ctx.json([])),
);

export const pricesHandlers = [pricesHandler];
