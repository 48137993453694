import React, { ReactElement, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import _environment from '../../_environment/_environment';
import moment from 'moment';
import useLanguage from '../../hooks/useLanguage';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/layout/loader/loader';
import { useTimeout } from 'react-use';
import { Image } from 'antd';
import Space from '../../components/layout/space/space';
import { useGetLanguagesQuery } from '../../hooks/react-query/useGetLanguagesQuery/useGetLanguagesQuery';


interface Props {
    children: any;
}

export interface ILanguageContext {}

export const LanguageContext = React.createContext<ILanguageContext>({});

export const LanguageContextProvider = ({ children }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [isI18nInitialized, setI18nInitialized] = useState<boolean>(false);

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    const [isReady] = useTimeout(2000);

    const location = useLocation();
    const params = location.pathname.split('/').filter((param) => !!param);
    const lang = params[0];
    const idReseller = params[1];

    // Access to antd locales manager hook.
    const { getIso2ByLang } = useLanguage();

    // Subscription to a fetch query
    const {
        data: translationsFiles,
        isFetching: isFetchingTranslations,
        isError: isFetchingTranslationsFailed,
    } = useGetLanguagesQuery(`${lang}`, !!lang);

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Initialize the i18n module
     *
     * @function
     * @returns {void}
     */
    const initLanguage = (): void => {
        if (typeof lang !== 'string') return;

        const resources: { [key: string]: { translation?: string } } = {};

        resources[lang] = {};
        resources[lang].translation = translationsFiles;

        // Multilanguage setup
        i18n
            // load translation using http -> see /public/locales
            // learn more: https://github.com/i18next/i18next-http-backend
            .use(Backend)
            // detect user language
            // learn more: https://github.com/i18next/i18next-browser-languageDetector
            // .use(LanguageDetector)
            // pass the i18n instance to react-i18next.
            .use(initReactI18next)
            // init i18next
            // for all options read: https://www.i18next.com/overview/configuration-options
            .init({
                fallbackLng: _environment.i18n.fallbackLanguage,
                resources,
                debug: process.env.REACT_APP_ENV === 'development',
                react: {
                    useSuspense: false,
                },
                interpolation: {
                    escapeValue: false, // not needed for react as it escapes by default
                },
            })
            .then(() => {
                i18n.changeLanguage(lang);
                setI18nInitialized(true);
            });
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Render component content
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderContent = (): ReactElement | null => {
        if (!lang || !idReseller || isFetchingTranslationsFailed)
            return (
                <div className="center middle">
                    <Image src="/assets/images/resources/logo/marinelli.png" preview={false} />
                    <Space multiplier={8} />
                    <Image src="/assets/images/undraw/404.svg" width={400} preview={false} />
                    <Space multiplier={6} />
                    <p>
                        There seems to be a problem with the URL. Please contact the person who
                        provided it.
                    </p>
                </div>
            );

        if (isFetchingTranslations || !isReady() || !isI18nInitialized) return <Loader />;

        if (isI18nInitialized) return children;
        return null;
    };

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Language provider] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Language provider] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to translationsFiles in order to Initialise the i18n
     * module only when the translation files have been fetched.
     */
    useEffect(() => {
        if (!!translationsFiles) {
            initLanguage();
        }
    }, [translationsFiles]);

    /**
     * Hook to isI18nInitialized in order to set the default language for the various components
     */
    useEffect(() => {
        if (isI18nInitialized) {
            const currentLanguage: string = getIso2ByLang(i18n.language);

            // Set the default language for all moment objects
            moment.locale(currentLanguage);
        }
    }, [isI18nInitialized]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return <LanguageContext.Provider value={{}}>{renderContent()}</LanguageContext.Provider>;
};
