// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { Extension, IGetExtensions } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { extensionsPath } from '../../mocks/handlers/extensionsHandler';

/**
 * Returns configuration according to choises history
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IGetPrices} data - Input data
 * @returns {Promise<ExtensionsResponse>}
 */
export const getExtensions = async (data: IGetExtensions): Promise<Array<Extension>> => {
    try {
        const response: AxiosResponse = await httpClient.get(extensionsPath, {
            params: data,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err?.response?.data?.message;
    }
};
