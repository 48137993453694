import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Modal } from 'antd';


interface Props {
    children: any;
}

interface ModalContextData {
    title: string;
    closable?: boolean;
    content?: ReactNode;
    showButtons?: boolean;
    wrapClassName?: string;
    callback?: () => void;
    onCancel?: () => void;
}

export interface IModalContext {
    openModal: (dataIgnored: ModalContextData) => void;
    closeModal: () => void;
}

export const ModalContext = React.createContext<IModalContext>({
    openModal: () => {},
    closeModal: () => {},
});

export const ModalContextProvider = ({ children }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    const [isVisible, setVisible] = useState<boolean>(false);
    const [callback, setCallback] = useState<(() => void) | undefined>(undefined);
    const [onCancel, setOnCancel] = useState<(() => void) | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [closable, setClosable] = useState<boolean>(true);
    const [content, setContent] = useState<ReactNode | undefined>(undefined);
    const [wrapClassName, setWrapClassName] = useState<string | undefined>(undefined);
    const [showButtons, setShowButtons] = useState<boolean>(true);

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * Opens the modal and set the state
     *
     * @function
     * @param {ModalContextData} data - Modal configuration data
     */
    const openModal = (data: ModalContextData): void => {
        setVisible(true);
        // If you were to use setCallback(data.callback) React setState WILL
        // call the callback function because it's interpretated as it is setState(prev => newValue)
        // Hence the necessity to have a function that returns a function (that then will be set as a state)
        setCallback((_Ignored) => data.callback);
        setOnCancel((_Ignored) => data.onCancel);
        setWrapClassName(data.wrapClassName);
        setTitle(data.title);
        setClosable(typeof data.closable !== 'undefined' ? data.closable : true);
        setContent(data.content);
        setShowButtons(data.showButtons || data.showButtons === undefined);
    };

    /**
     * Reset input parameters
     *
     * @function
     */
    const resetParams = (): void => {
        setCallback(undefined);
        setOnCancel(undefined);
        setWrapClassName(undefined);
        setTitle('');
        setClosable(true);
        setContent(undefined);
        setShowButtons(true);
    };

    /**
     * Close the modal and update the state
     *
     * @function
     */
    const closeModal = (): void => {
        resetParams();
        setVisible(false);
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------
    const renderModal = (): ReactElement | null => (
        <Modal
            title={title}
            closable={closable}
            wrapClassName={wrapClassName}
            visible={isVisible}
            footer={!showButtons ? null : undefined}
            onOk={() => {
                if (callback) {
                    callback();
                }
                closeModal();
            }}
            onCancel={() => {
                if (onCancel) {
                    onCancel();
                }
                closeModal();
            }}
        >
            {content}
        </Modal>
    );

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Modal provider] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Modal provider] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <ModalContext.Provider
            value={{
                openModal,
                closeModal,
            }}
        >
            {children}
            {renderModal()}
        </ModalContext.Provider>
    );
};
