/**
 * Composes the url by replacing placeholders with their respective values
 *
 * @function
 * @class
 * @category Utils
 * @subcategory Url
 * @param {string} path -The path to interpolate with data
 * @param {string} replacements - A vector with a single object containing the key that has to be overridden in the path with the value in the object for the same key
 * @param {boolean?} absolute - The path argument is relative or absolute (default is false)
 * @returns {string} - The composed path with all the "expansion" in place
 */
export function composePathWithParams(
    path: string,
    replacements: Array<any>,
    absolute = false,
): string {
    let res = path;
    replacements.forEach((item) => {
        Object.keys(item).forEach((key) => {
            res = res.replace(`:${key}`, item[key]);
        });
    });
    return `${absolute ? window.location.origin : ''}${res}`;
}

/* export const imageExists = async (url?: string): Promise<string> => {
    try {
        if (!!url) {
            const http = new XMLHttpRequest();

            http.open('HEAD', url, false);
            http.send();

            if (http.status !== 404) {
                return url;
            }
            return '';
        }
        return '';
    } catch (err) {
        return '';
    }
}; */
