// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { IGetPrices, PriceInformations } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { pricesPath } from '../../mocks/handlers/pricesHandler';

/**
 * Returns configuration according to choises history
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {IGetPrices} data - Input data
 * @returns {Promise<PriceInformations>}
 */
export const getPrices = async (data: IGetPrices): Promise<Array<PriceInformations>> => {
    try {
        const response: AxiosResponse = await httpClient.get(pricesPath, {
            params: data,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err?.response?.data?.message;
    }
};
