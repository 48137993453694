import { Dispatch } from 'react';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ContextResellerAction, CONTEXT_RESELLER_TYPES } from './contextReseller/types';
import { resellersPath } from '../../mocks/handlers/resellersHandler';

// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------

/**
 * Ritorna la lista dei resellers secondo specifici criteri
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Reseller
 * @param {string} id - Reseller id to download
 */
export const getContextReseller =
    (id: string) => async (dispatch: Dispatch<ContextResellerAction>) => {
        try {
            dispatch({ type: CONTEXT_RESELLER_TYPES.PENDING });

            const params: any = {
                id,
            };

            const response: AxiosResponse = await httpClient.get(resellersPath, {
                params,
            });

            dispatch({
                type: CONTEXT_RESELLER_TYPES.COMPLETED,
                payload: response.data,
            });
        } catch (exception) {
            const err: any = exception;
            dispatch({
                type: CONTEXT_RESELLER_TYPES.ADD_ERROR,
                payload: err.response?.data?.message,
            });
        }
    };
