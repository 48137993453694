import React, { ReactElement, useEffect } from 'react';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChoicesHistory } from '../../data/interfaces';
import { BaseType } from '@marinelli/shared-types';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    choicesHistory: ChoicesHistory;
}

const ProgressBar = ({ choicesHistory, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Returns the length of wall 1 currently occupied according to the choices made
     *
     * @function
     * @returns {number}
     */
    const getCurrentSide1Length = (): number => {
        return choicesHistory.configuration?.sideOneFinal || choicesHistory.base?.sideOne || 0;
    };

    /**
     * Returns the length of wall 2 currently occupied according to the choices made
     *
     * @function
     * @returns {number}
     */
    const getCurrentSide2Length = (): number => {
        return choicesHistory.configuration?.sideTwoFinal || choicesHistory.base?.sideTwo || 0;
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[ProgressBar] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[ProgressBar] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return !!choicesHistory.maxLength1 ? (
        <div className="progress-bar-container">
            <div className="progress-bar">
                <p>
                    {t(
                        `components.progressBar.${choicesHistory.layout}${
                            choicesHistory.layout === BaseType.ANGULAR ? '.side_1' : ''
                        }`,
                    )}
                </p>
                <Progress
                    steps={5}
                    percent={(getCurrentSide1Length() / choicesHistory.maxLength1) * 100}
                    format={() =>
                        t('components.progressBar.used', {
                            current: getCurrentSide1Length(),
                            total: choicesHistory.maxLength1,
                        })
                    }
                />
            </div>
            {choicesHistory.layout === BaseType.ANGULAR && !!choicesHistory.maxLength2 && (
                <div className="progress-bar">
                    <p>{t(`components.progressBar.${choicesHistory.layout}.side_2`)}</p>
                    <Progress
                        steps={5}
                        percent={(getCurrentSide2Length() / choicesHistory.maxLength2) * 100}
                        format={() =>
                            t('components.progressBar.used', {
                                current: getCurrentSide2Length(),
                                total: choicesHistory.maxLength2,
                            })
                        }
                    />
                </div>
            )}
        </div>
    ) : null;
};
export default ProgressBar;
