import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';

export const translationsResponseMock = {
    ids: {
        '0': {
            title: 'Base con anta ',
            size: '40cm H 72cm',
        },
        '1': {
            title: 'Base con anta ',
            size: '45cm H 72cm',
        },
        '2': {
            title: 'Base cassetto ',
            size: '45cm H 72cm',
        },
        '3': {
            title: 'Lavastoviglie ',
            size: '60cm H 82cm',
        },
        '4': {
            title: 'Base con anta ',
            size: '60cm H 72cm',
        },
        '5': {
            title: 'Colonna dispensa ',
            size: '30cm H 204cm',
            tooltip: 'Colonna dispensa con cestone estraibile',
        },
        '6': {
            title: 'Base con anta ',
            size: '30cm H 72cm',
        },
        '7': {
            title: 'Colonna frigo',
            size: '60cm H 204cm',
        },
        '8': {
            title: 'Pensile con anta',
            size: '30cm H 72cm',
        },
        '9': {
            title: 'Pensile con anta',
            size: '40cm H 72cm',
        },
        '10': {
            title: 'Pensile con anta',
            size: '45cm H 72cm',
        },
        '11': {
            title: 'Pensile con anta',
            size: '60cm H 72cm',
        },
        '12': {
            text: 'Tamponamento',
            size: 'L 15cm H 204cm',
            tooltip: `L'elemento di tamponamento serve se non hai trovato la misura ideale corrispondente alla tua parete, per chiudere lo spazio vuoto rimanente. Viene posizionato con staffe ad L (escluse dalla nostra fornitura) a lato delle colonne della tua cucina. Ha una larghezza di 15 cm ed è rifilabile in opera.`,
        },
        '13': {
            title: 'Tavolo',
            size: 'L. 60cm H 84cm',
            tooltip: 'Tavolo penisola per installazione a fine composizione',
        },
    },
    components: {
        cards: {
            button: 'Seleziona',
        },
        progressBar: {
            linear: 'Spazio occupato: ',
            angular: {
                side_1: 'Spazio occupato lato 1: ',
                side_2: 'Spazio occupato lato 2: ',
            },
            used: '{{current}}cm su {{total}}cm',
        },
        stepperNavigator: {
            buttons: {
                back: 'Indietro',
                next: 'Avanti',
            },
        },
        sideMenu: {
            list: 'Lista',
            kitchenType: 'Tipologia di cucina selezionata',
            configuration: {
                title: 'Configurazione',
                description:
                    'Da qui si possono modificare le selezioni fatte: si torna al punto desiderato, ma si perdono le configurazioni successive',
            },
            components: {
                layout: {
                    linear: 'Cucina Lineare',
                    angular: 'Cucina Angolare',
                },
                handle: {
                    internal: 'Maniglia integrata,a gola',
                    external: 'Maniglia frontale',
                },
                oven: {
                    under: 'Forno sotto il piano cottura',
                    column: 'Forno in una colonna',
                },
                base: {
                    linear: 'Base cucina - {{size1}} cm',
                    angular: 'Base cucina - {{size1}} cm x {{size2}} cm',
                },
                extensions: {
                    linear: 'Allungamenti',
                    angular: {
                        side_1: 'Allungamenti Lato 1',
                        side_2: 'Allungamenti Lato 2',
                    },
                },
                hob: {
                    gas: 'Piano cottura a gas',
                    ceramic: 'Piano cottura in vetroceramica',
                },
                accessories: 'Accessori',
            },
            noData: {
                title: 'Ecco la lista delle scelte fatte',
                description:
                    'Qui verrano visualizzate le informazioni e le scelte fatte, in base a ciò che selezionerai nel corso della configurazione. Cosa aspetti?\nComincia subito a costruire la tua cucina!',
            },
            modal: {
                title: 'Attenzione: stai per perdere le scelte fatte sinora',
                description:
                    'Tornando alla selezione del componente indicato, perderai la configurazione della tua cucina attuale. \nVuoi procedere? ',
                buttons: {
                    cancel: 'Annulla',
                    confirm: 'Procedi',
                },
            },
        },
    },
    screens: {
        intro: {
            title: 'Benvenuto',
            description:
                'Creare una nuova cucina non è mai stato così facile. In pochi semplici passi guidati puoi selezionare le caratteristiche ed i componenti necessari per la tua prossima cucina.',
            button: 'Vai al configuratore',
        },
        selector: {
            index: {
                stepper: {
                    types: 'Tipo Cucina',
                    structure: 'Struttura',
                    base: 'Base Cucina',
                    extensions: 'Allungamenti',
                    accessories: 'Accessori',
                    colors: 'Colori',
                },
            },
            steps: {
                types: {
                    kitchenLayout: {
                        title: 'Che tipologia di cucina ti serve?',
                        card: {
                            linear: {
                                title: 'Cucina lineare',
                                description: 'Tutta la cucina su una parete',
                            },
                            angular: {
                                title: 'Cucina angolare',
                                description: 'Componenti su due pareti',
                            },
                        },
                    },
                    maxLength: {
                        description: 'Inserisci qui la misura massima possibile, in cm',
                        success_message: 'La misura inserita rientra tra quelle disponibili',
                        inputNumber_placeholder: 'Inserisci la misura (cm)',
                        note_title: 'Nota: ',
                        linear: {
                            title: 'Qual è la dimensione massima della tua cucina lineare?',
                            message: {
                                note: 'ti segnalo che la versione più grande disponibile arriva a {{val}} cm',
                                warning:
                                    'Attenzione: non sono previste cucine con lunghezze inferiori a {{val}} cm',
                            },
                        },
                        angular: {
                            side_1: {
                                title: 'Qual è la dimensione massima del lato 1 della tua cucina angolare?',
                                message: {
                                    note: 'considera che la lunghezza massima del primo lato è {{val}} cm',
                                    warning:
                                        'Attenzione: la misura minima del lato 1 è {{val}} cm, allungabile sino a {{max}} cm',
                                },
                            },
                            side_2: {
                                title: 'Che lunghezza massima può avere il lato 2 della tua cucina angolare?',
                                message: {
                                    note: 'considera che la lunghezza massima del secondo lato è 280 cm',
                                    warning:
                                        'Attenzione: la misura minima del lato 2 è {{val}} cm, allungabile sino a {{max}} cm',
                                },
                            },
                        },
                    },
                },
                structure: {
                    handle: {
                        title: 'Scegli la tipologia di apertura che preferisci per la tua nuova cucina',
                        card: {
                            internal: {
                                title: 'Maniglia integrata, a gola',
                                description: `Con profilo in alluminio`,
                                tooltip: `Il profilo in alluminio è situato in un incavo dell'anta`,
                            },
                            external: {
                                title: 'Maniglia frontale',
                                description: 'In metallo',
                                tooltip: `Tieni presente che, scegliendo la maniglia frontale, avrai la possibilità di invertire la posizione dei componenti della cucina, anche direttamente durante il montaggio`,
                            },
                        },
                    },
                    oven: {
                        title: 'Dove preferisci posizionare il forno della tua nuova cucina?',
                        card: {
                            under: {
                                title: 'Sotto il piano cottura',
                                description: 'Collocazione classica, sotto i fuochi',
                            },
                            column: {
                                title: 'In una colonna',
                                description: `Area dall'utilizzo più pratico, a lato del piano cottura`,
                            },
                        },
                    },
                },
                base: {
                    title: 'Scegli da quale delle seguenti composizioni di base vuoi partire per comporre la tua cucina',
                    toggle: 'Scopri le soluzioni con componenti invertiti',
                    noData: 'Non è stato possibile trovare degli allungamenti',
                    linear: {
                        option_1: '195 cm',
                        option_2: '210 cm',
                        option_3: '250 cm',
                    },
                    angular: {
                        option_1: {
                            side_1: 'Lato 1, con colonna forno, allungabile sino a 340 cm',
                            side_2: 'Lato 2 allungabile sino a 225 cm',
                        },
                        option_2: {
                            side_1: 'Lato 1 allungabile sino a 300 cm',
                            side_2: 'Lato 2, con lavastoviglie, allungabile sino a 280 cm',
                        },
                    },
                },
                extensions: {
                    noData: 'Non è stato possibile trovare degli allungamenti',
                    configuration: {
                        noData: 'Non è disponibile una configurazione per questi parametri',
                    },
                    extensions: {
                        noData: 'Non ci sono allungamenti disponibili',
                    },
                    linear: {
                        title: 'In questa pagina troverai tutti i moduli che possono essere aggiunti alla tua cucina, per allungarla sino alla lunghezza desiderata',
                    },
                    angular: {
                        title: {
                            side_1: 'In questa pagina troverai tutti i moduli che possono essere aggiunti al LATO 1 della tua cucina angolare, per allungarla sino alla lunghezza desiderata',
                            side_2: 'In questa pagina troverai tutti i moduli che possono essere aggiunti al LATO 2 della tua cucina angolare, per allungarla sino alla lunghezza desiderata',
                        },
                    },
                    description:
                        'Se non hai bisogno di allungare la cucina scelta, clicca su Avanti',
                    text: {
                        reset: 'Resetta le scelte fatte',
                        click: 'Clicca qui',
                        assembly_alternative: ` per scoprire un'alternativa di montaggio possibile`,
                    },
                    modal: {
                        title: 'Istruzioni per montaggio alternativo',
                        description: `La composizione scelta permette di spostare le colonne su una parete separata, realizzando una cucina su due lati, senza collegamento d'angolo`,
                    },
                    alert: {
                        title: 'Attenzione!',
                        top_height_over:
                            'Il pallet su cui la cucina verrà spedita è superiore a 220 cm.\nIn caso di consegna a domicilio, i corrieri potrebbero aver bisogno di utilizzare camion da 7,5 metri minimo',
                        accessories:
                            'Se in alcune combinazioni ti sembra che manchi qualche elemento, non preoccuparti: Avrai la possibilità di scegliere nella pagine successive i componenti necessari per completarla',
                    },
                },
                accessories: {
                    hob: {
                        title: 'Scegli ora il piano cottura desiderato',
                        ceramic: {
                            title: 'Vetroceramica',
                            description: 'Con 4 fuochi',
                        },
                        gas: {
                            title: 'A gas',
                            description: 'Con 4 fuochi',
                        },
                    },
                    kitchenCabinets: {
                        reset: 'Resetta le scelte fatte',
                        title: 'Vuoi completare la tua cucina con pensili ed altri accessori?',
                        description: 'Se non hai bisogno di accessori, clicca su Avanti',
                    },
                    noData: 'Non è stato possibile trovare degli accessori',
                },
                colors: {
                    title: 'Scegli la combinazione di colori preferita',
                    note: {
                        title: 'Nota: ',
                        text: 'immagini esemplificative',
                    },
                    card: {
                        title: 'Scopri le soluzioni disponibili',
                        dropdown: {
                            title: 'Selezione di Colori',
                            paletteTitle: 'Combinazione di colore {{index}}',
                            noData: 'Non è stata trovata nessuna combinazione di colori per la configurazione scelta.',
                        },
                        list: {
                            0: 'Colore basi inferiori cucina',
                            1: 'Colore top',
                            2: 'Colore pensili cucina',
                            noData: 'Nessun colore selezionato',
                        },
                    },
                    configuration: {
                        noData: 'Non è stata trovata nessuna configurazione per questi parametri',
                    },
                    noData: 'Non ci sono combinazioni di colore disponibili',
                },
            },
        },
    },
    providers: {
        setup: {
            failedFetchMessage:
                "Sembra ci sia un problema con l'URL. Contatta la persona che te lo ha fornito.",
        },
    },
    forms: {
        errors: {
            type_error_number: 'Il valore deve essere numerico',
        },
    },
    errors: {
        general: {
            description: 'Si è verificato un problema, riprova più tardi',
        },
    },
};

export const translationsPath = `${baseUrl}/env/translations`;

const translationsHandler = rest.get(translationsPath, async (req, res, ctx) =>
    res(ctx.json(translationsResponseMock)),
);
export const translationsHandlerException = rest.get(translationsPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);

export const translationsHandlers = [translationsHandler];
