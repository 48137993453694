import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import _environment from './_environment/_environment';
import * as serviceWorker from './serviceWorker';
import App from './App';

// Define a new console function
const console = (function (oldCons: Console): Console {
    return {
        ...oldCons,
        log(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.log.apply(oldCons, args);
            }
        },
        info(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.info.apply(oldCons, args);
            }
        },
        warn(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.warn.apply(oldCons, args);
            }
        },
        error(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.error.apply(oldCons, args);
            }
        },
        trace(...args: any) {
            if (_environment.showHandledLogs) {
                oldCons.trace.apply(oldCons, args);
            }
        },
    };
})(window.console);

// Then redefine the old console
window.console = console;

if (_environment.api.baseURL === 'http://localhost:3030') {
    const { worker } = require('./mocks/browser');
    worker.start();
}

// Sentry tracking monitor
Sentry.init({
    dsn: _environment.sentry.dsn,
    enabled: _environment.sentry.enabled,
    environment: process.env.REACT_APP_ENV,
    release: _environment.version,
});
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
