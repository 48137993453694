import { useEffect } from 'react';
import { ROUTES } from '../config/routing/routes';
import { COOKIES } from '../modules/common/data/enums';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------
interface useRoutesData {
    getHomeRoute(): string;
}

/**
 * Custom hook
 *
 * @function
 * @class
 * @category Utils
 * @subcategory Hooks
 */
function useRoutes(): useRoutesData {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Returns the route representing the home page
     *
     * @function
     * @returns {string}
     */
    const getHomeRoute = (): string => {
        if (!localStorage.getItem(COOKIES.INTRO)) {
            return ROUTES.INTRO;
        }
        return ROUTES.HOME;
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[useRoutes] init');
    };
    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[useRoutes] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    return { getHomeRoute };
}

export default useRoutes;
