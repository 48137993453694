import React, { ReactElement, useEffect } from 'react';
import { useQuery } from 'react-query';
import useQueries from '../../hooks/useQueries';
import { QUERIES_KEYS } from '../../modules/common/data/enums';
import { getContextReseller } from '../../redux/resellers/action';
import { useDispatch, useSelector } from 'react-redux';
import { ContextResellerState } from '../../redux/resellers/contextReseller/reducer';
import Loader from '../../components/layout/loader/loader';
import Space from '../../components/layout/space/space';
import { Image } from 'antd';
import useUiCustomization from '../../hooks/useUiCustomization';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


interface Props {
    children: any;
}

export interface ISetupContext {}

export const SetupContext = React.createContext<ISetupContext>({
    openSetup: () => {},
    closeSetup: () => {},
});

export const SetupContextProvider = ({ children }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // Get data from the authReducer store state
    const { reseller, errorMessage: isFetchingResellerFailed } = useSelector(
        ({ contextResellerReducer }: { contextResellerReducer: ContextResellerState }) =>
            contextResellerReducer,
    );

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Reference to Redux store dispatch function: use it to dispatch actions.
    const dispatch = useDispatch();

    // Access to UI Customization manager hook.
    const { changeAntdComponentsColor } = useUiCustomization();

    const location = useLocation();
    const params = location.pathname.split('/').filter((param) => !!param);
    const [lang, idReseller] = params;

    const { getFetchRequestQueryParams }: any = useQueries();

    // Subscription to a fetch query
    const { isFetching: isFetchingReseller } = useQuery<any>( // TODO: TYPE ME
        [
            QUERIES_KEYS.GET_RESELLER,
            {
                id: idReseller,
            },
        ],
        async ({ queryKey }: any) => {
            const { id } = queryKey[1];

            dispatch(getContextReseller(id));
        },
        getFetchRequestQueryParams({
            enabled: !!idReseller,
        }),
    );

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render component content
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderContent = (): ReactElement | null => {
        if (!!isFetchingResellerFailed || !reseller?.countries?.includes(lang)) {
            return (
                <div className="center middle">
                    <Image preview={false} src="/assets/images/resources/logo/marinelli.png" />
                    <Space multiplier={8} />
                    <Image preview={false} src="/assets/images/undraw/404.svg" width={400} />
                    <Space multiplier={6} />
                    <p>{t('providers.setup.failedFetchMessage')}</p>
                </div>
            );
        }

        if (isFetchingReseller) return <Loader />;

        if (!!reseller) return children;
        return null;
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Setup provider] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Setup provider] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    /**
     * Hook to reseller in order to update UI Customization
     */
    useEffect(() => {
        if (!!reseller?.color) {
            changeAntdComponentsColor(reseller.color);
        }
    }, [reseller]);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return <SetupContext.Provider value={{}}>{renderContent()}</SetupContext.Provider>;
};
