import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HandleType } from '@marinelli/shared-types';
import ActionCard from '../../../../../components/cards/actionCard/actionCard';
import useBases from '../../../../../hooks/useBases';
import { ChoicesHistory } from '../../../../../data/interfaces';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    stepperProps: StepperProps;
    choicesHistory: ChoicesHistory;
    onSelect: (choosenData: HandleType) => void;
}
/**
 * View representing the structural options to add handle type
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {(dataIgnored: STRUCTURES_TYPES) => void} anonymousObject.onSelect - Called when an element is selected
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const HandleTypeView = ({
    onSelect,
    stepperProps,
    choicesHistory,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    const handleTypes: Array<HandleType> = [HandleType.INTERNAL, HandleType.EXTERNAL];

    // Access to bases manager hook.
    const { mapBaseHandleTypeIntoActionCard } = useBases();

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[HandleTypeView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[HandleTypeView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-step-handle-type">
            <StepperNavigator onPrevious={stepperProps.onPrevious} onNext={stepperProps.onNext} />
            <header className="card-header large">
                <h4 className="card-title">
                    {' '}
                    {t(`screens.selector.steps.structure.handle.title`)}{' '}
                </h4>
            </header>

            <div className="card-list">
                {handleTypes.map((handle: HandleType) =>
                    !!choicesHistory.layout ? (
                        <ActionCard
                            key={handle}
                            actionCard={mapBaseHandleTypeIntoActionCard(
                                handle,
                                choicesHistory.layout,
                                (choosenHandle: HandleType) => {
                                    onSelect(choosenHandle);
                                },
                            )}
                        />
                    ) : null,
                )}
            </div>
        </div>
    );
};
export default HandleTypeView;
