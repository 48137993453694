import { IGetPrices, PriceInformations } from '@marinelli/shared-types';
import { useQuery, UseQueryResult } from 'react-query';
import { QUERIES_KEYS } from '../../../modules/common/data/enums';
import { getPrices } from '../../../redux/prices/action';
import useQueries from '../../useQueries';

/**
 * @param {IGetPrices} data - Input data
 * @returns {UseQueryResult<Array<PriceInformations>, any>}
 */
export const useGetPricesQuery = (
    data: IGetPrices,
): UseQueryResult<Array<PriceInformations>, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_PRICES],
        async () => {
            return getPrices(data);
        },
        getFetchRequestQueryParams({
            retry: !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development',
        }),
    );
};
