// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { IGetBases, KitchenBase } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { basesPath } from '../../mocks/handlers/basesHandler';

/**
 * Returns bases according to choises history
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Bases
 * @param {IGetBases} data - Input data
 * @returns {Promise<Array<KitchenBase>>}
 */
export const getBases = async (data: IGetBases): Promise<KitchenBase> => {
    try {
        const response: AxiosResponse = await httpClient.get(basesPath, {
            params: data,
        });

        return response.data;
    } catch (exception) {
        const err: any = exception;
        throw err?.response?.data?.message;
    }
};
