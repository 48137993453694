import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'antd';
import { SELECTOR_STEPS, STEP_COMPONENTS } from '../../../../data/enums';
import { ChoicesHistory, Step } from '../../../../data/interfaces';
import SideMenu from '../../../../components/layout/sideMenu/sideMenu';
import ProgressBar from '../../../../components/progressBar/progressBar';
import SelectorIndexLinearFlowView from './linearFlow';
import StepTypesKitchenLayoutView from '../steps/kitchenLayout/kitchenLayout';
import { BaseType } from '@marinelli/shared-types';
import SelectorIndexAngularFlowView from './angularFlow';
import Stepper from './components/stepper';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    initialStep?: SELECTOR_STEPS;
    initialStepContent?: STEP_COMPONENTS;
    initialChoicesHistory?: ChoicesHistory;
}

export const linearFlowSteps: Array<Step> = [
    {
        id: SELECTOR_STEPS.TYPES,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.TYPES}`,
        position: 0,
    },
    {
        id: SELECTOR_STEPS.STRUCTURE,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.STRUCTURE}`,
        position: 1,
    },
    {
        id: SELECTOR_STEPS.BASE,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.BASE}`,
        position: 2,
    },
    {
        id: SELECTOR_STEPS.EXTENSIONS,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.EXTENSIONS}`,
        position: 3,
    },
    {
        id: SELECTOR_STEPS.ACCESSORIES,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.ACCESSORIES}`,
        position: 4,
    },
    {
        id: SELECTOR_STEPS.COLORS,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.COLORS}`,
        position: 5,
    },
    {
        id: SELECTOR_STEPS.SUMMARY,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.SUMMARY}`,
        position: 6,
        hidden: true,
    },
];

export const angularFlowSteps: Array<Step> = [
    {
        id: SELECTOR_STEPS.TYPES,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.TYPES}`,
        position: 0,
    },
    {
        id: SELECTOR_STEPS.BASE,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.BASE}`,
        position: 1,
    },
    {
        id: SELECTOR_STEPS.STRUCTURE,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.STRUCTURE}`,
        position: 2,
    },
    {
        id: SELECTOR_STEPS.EXTENSIONS,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.EXTENSIONS}`,
        position: 3,
    },
    {
        id: SELECTOR_STEPS.ACCESSORIES,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.ACCESSORIES}`,
        position: 4,
    },
    {
        id: SELECTOR_STEPS.COLORS,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.COLORS}`,
        position: 5,
    },
    {
        id: SELECTOR_STEPS.SUMMARY,
        title: `screens.selector.index.stepper.${SELECTOR_STEPS.SUMMARY}`,
        position: 6,
        hidden: true,
    },
];

/**
 * COMMENT ME
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {SELECTOR_STEPS} anonymousObject.initialStep - Default step from which to start
 * @param {STEP_COMPONENTS} anonymousObject.initialStepContent - Default step content from which to start
 * @param {ChoicesHistory} anonymousObject.initialChoicesHistory - Initial choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SelectorIndexView = ({
    initialStep,
    initialStepContent,
    initialChoicesHistory,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [choicesHistory, setChoisesHistory] = useState<ChoicesHistory | undefined>(
        initialChoicesHistory,
    );

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render the contents of the current (active) step
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderProgressBar = (): ReactElement | null => {
        return !!choicesHistory?.maxLength1 && !choicesHistory.colors ? (
            <ProgressBar choicesHistory={choicesHistory} />
        ) : null;
    };

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SelectorIndexView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SelectorIndexView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <Card id="selector">
            {!!choicesHistory?.layout ? (
                <>
                    {choicesHistory.layout === BaseType.LINEAR ? (
                        <SelectorIndexLinearFlowView
                            {...restProps}
                            steps={linearFlowSteps}
                            choicesHistory={choicesHistory}
                            initialStep={initialStep}
                            initialStepContent={initialStepContent}
                            onHistoryUpdate={setChoisesHistory}
                        />
                    ) : (
                        <SelectorIndexAngularFlowView
                            {...restProps}
                            steps={angularFlowSteps}
                            choicesHistory={choicesHistory}
                            initialStep={initialStep}
                            initialStepContent={initialStepContent}
                            onHistoryUpdate={setChoisesHistory}
                        />
                    )}
                </>
            ) : (
                <>
                    <Stepper steps={linearFlowSteps} stepPosition={0} />
                    <StepTypesKitchenLayoutView
                        onSelect={(choosenLayout: BaseType) => {
                            // Update history
                            setChoisesHistory({
                                ...choicesHistory,
                                ...{
                                    layout: choosenLayout,
                                },
                            });
                        }}
                    />
                    <SideMenu choicesHistory={choicesHistory} />
                </>
            )}

            {renderProgressBar()}
        </Card>
    );
};
export default SelectorIndexView;
