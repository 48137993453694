// -----------------------------------------------------------------
// A c t i o n s
// -----------------------------------------------------------------
import { ICreateOrderDto } from '@marinelli/shared-types';
import { AxiosResponse } from 'axios';
import { httpClient } from '../../config/http-requests';
import { ordersPath } from '../../mocks/handlers/ordersHandler';

/**
 * Returns configuration according to choises history
 *
 * @function
 * @async
 * @class
 * @category HTTP Methods
 * @subcategory Configuration
 * @param {ICreateOrderDto} data - Input data
 * @returns {Promise<any>}
 */
export const createOrder = async (data: ICreateOrderDto): Promise<any> => {
    try {
        const response: AxiosResponse = await httpClient.post(ordersPath, data, {
            responseType: 'blob',
        });
        const fileName = `${data.configId}.pdf`;

        return {
            contentType: response.headers['content-type'],
            content: response.data,
            fileName,
        };
    } catch (exception) {
        const err: any = exception;
        throw err?.response?.data?.message;
    }
};
