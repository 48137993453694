import { rest } from 'msw';
import { baseUrl } from '../../config/http-requests';
import { ColorsResponse } from '../../data/interfaces';
import { colors, configuration } from '../data';

export const colorsPath = `${baseUrl}/configs/colors`;

export const colorsResponseMock: ColorsResponse = {
    colors: [colors, colors, colors],
    configuration,
};

// Simulates a configuration with colors to be choose
const colorsHandler = rest.get(colorsPath, async (req, res, ctx) =>
    res(ctx.json(colorsResponseMock)),
);

// Returns an error exception
export const colorsHandlerException = rest.get(colorsPath, async (req, res, ctx) =>
    res(ctx.status(500), ctx.json({ message: 'Deliberately broken request' })),
);

/* Simulates a response that found no available configuration
(When due to a DB-side error, no configuration is found) */
export const colorsNoConfigurationHandler = rest.get(colorsPath, async (req, res, ctx) => {
    const { configuration: conf, ...restObj } = colorsResponseMock;

    return res(ctx.status(200), ctx.json(restObj));
});

/* Simulates a response with a configuration with no colors to choose
(When there are no colors available to apply on the configuration) */
export const colorsNoColorsToChooseHandler = rest.get(colorsPath, async (req, res, ctx) =>
    res(
        ctx.status(200),
        ctx.json({
            colors: [],
            configuration,
        }),
    ),
);

export const colorsHandlers = [colorsHandler];
