import React, { ReactElement, useEffect } from 'react';
import ActionCard from '../../../../../components/cards/actionCard/actionCard';
import { useTranslation } from 'react-i18next';
import useBases from '../../../../../hooks/useBases';
import { BaseType } from '@marinelli/shared-types';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    onSelect: (dataIgnored: BaseType) => void;
}

/**
 * View representing the two kitchen types (linear/angular)
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {(dataIgnored: BASE_TYPES) => void} anonymousObject.onSelect - Called when a type is selected
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const StepTypesKitchenLayoutView = ({ onSelect, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to bases manager hook.
    const { mapBaseTypesIntoActionCard } = useBases();

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Steps
    const types: Array<BaseType> = [BaseType.LINEAR, BaseType.ANGULAR];

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------
    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[StepTypesKitchenLayoutView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[StepTypesKitchenLayoutView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-step-kitchen-layout">
            <header className="card-header large">
                <h4 className="card-title">
                    {' '}
                    {t('screens.selector.steps.types.kitchenLayout.title')}
                </h4>
            </header>
            <div className="card-list">
                {types.map((bt: BaseType) => (
                    <ActionCard
                        key={bt}
                        actionCard={mapBaseTypesIntoActionCard(bt, (choosenType: BaseType) => {
                            onSelect(choosenType);
                        })}
                    />
                ))}
            </div>
        </div>
    );
};
export default StepTypesKitchenLayoutView;
