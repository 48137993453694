import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { ChoicesHistory } from '../../../../../data/interfaces';
import { useTranslation } from 'react-i18next';
import LayoutWithSidebar from '../../../../../components/layout/layoutWithSidebar/layoutWithSidebar';
import Slider from 'react-slick';
import { List, Checkbox, Button, Empty, Tooltip } from 'antd';
import StepperNavigator, {
    StepperProps,
} from '../../../../../components/stepperNavigator/stepperNavigator';
import { Accessory, ConfigurationImages, PossibleDirection } from '@marinelli/shared-types';
import MarinelliImage from '../../../../../components/layout/image/image';
import i18n from 'i18next';
import useBases from '../../../../../hooks/useBases';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    stepperProps: StepperProps;
    choicesHistory: ChoicesHistory;
}
/**
 * View representing the structural options to add (type of handles/oven position)
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const KitchenCabinetsView = ({
    stepperProps,
    choicesHistory,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Reference to Slider component to access all methods and properties
    const sliderRef = useRef<Slider>(null);

    // Slider settings configurations
    const slickSliderSettings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // Access to bases manager hook.
    const { getAccessoriesTranslationKey } = useBases();

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [chosenAccessories, setChosenAccessories] = useState<
        Array<{ accessory: Accessory; position: number }>
    >(
        !!choicesHistory.accessories
            ? choicesHistory.accessories.map((a: Accessory, i: number) => {
                  return { accessory: a, position: i };
              })
            : [],
    );

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render left column content
     *
     * @function
     * @returns {ReactElement}
     */
    const renderLeftColumn = (): ReactElement | null => {
        return (
            <>
                <div className="slider-container">
                    {/* <Slider ref={sliderRef} {...slickSliderSettings}>
                        {choicesHistory.configuration?.imagesUrl
                            .filter((path, i) => i === ConfigurationImages.ACCESSORIZED)
                            .map((url: string) => (
                                <div
                                    key={`${choicesHistory.configuration?.id}_image`}
                                    className="slick-slide"
                                >
                                    <Image
                                        fallback="https://via.placeholder.com/500/32495C/FFFFFF?text=No image"
                                        src={url}
                                        alt={`${choicesHistory.configuration?.id}`}
                                    />
                                </div>
                            ))}
                    </Slider> */}
                    {!!choicesHistory.configuration && (
                        <Slider ref={sliderRef} {...slickSliderSettings}>
                            <MarinelliImage
                                src={[
                                    choicesHistory.configuration.imagesUrl[
                                        choicesHistory.direction === PossibleDirection.R2L
                                            ? ConfigurationImages.ACCESSORIZED_REV
                                            : ConfigurationImages.ACCESSORIZED
                                    ],
                                    choicesHistory.configuration.imagesUrl[
                                        choicesHistory.direction === PossibleDirection.R2L
                                            ? ConfigurationImages.GENERAL_REV
                                            : ConfigurationImages.GENERAL
                                    ],
                                ]}
                                alt={`${choicesHistory.configuration?.id}`}
                                size={500}
                            />
                        </Slider>
                    )}
                </div>
            </>
        );
    };

    /**
     * Render right column content
     *
     * @function
     * @returns {ReactElement}
     */
    const renderRightColumn = (): ReactElement | null => {
        return (
            <>
                {chosenAccessories.length > 0 && (
                    <div className="restart-container">
                        <Button
                            type="link"
                            title={t('props.title.accessories.reset')}
                            onClick={() => setChosenAccessories([])}
                        >
                            {t('screens.selector.steps.accessories.kitchenCabinets.reset')}
                            <i className="ri-restart-line" />
                        </Button>
                    </div>
                )}
                {choicesHistory.configuration?.accessories.length === 0 ? (
                    <Empty
                        description={t('screens.selector.steps.accessories.noData')}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                ) : (
                    <List
                        dataSource={choicesHistory.configuration?.accessories}
                        renderItem={(accessory: Accessory, i: number) => (
                            <List.Item key={`${accessory.id}_${i}`}>
                                <div className="image-container">
                                    <MarinelliImage
                                        alt={`${accessory.id} accessory image`}
                                        src={[accessory.imageUrl]}
                                        preview
                                    />
                                </div>
                                <div className="checkbox-container">
                                    <Checkbox
                                        checked={
                                            chosenAccessories.filter(
                                                ({ accessory: a, position: p }) =>
                                                    a.id === accessory.id && p === i,
                                            ).length > 0
                                        }
                                        disabled={
                                            chosenAccessories.filter(
                                                ({ accessory: a, position: p }) =>
                                                    a.id === accessory.id && p === i,
                                            ).length > 0
                                        }
                                        onChange={({ target }: any) => {
                                            if (target.checked) {
                                                setChosenAccessories([
                                                    ...chosenAccessories,
                                                    ...[{ accessory, position: i }],
                                                ]);
                                            }
                                        }}
                                    >
                                        <div className="text-container">
                                            <h6>
                                                {t(
                                                    `ids.${getAccessoriesTranslationKey(
                                                        accessory.id,
                                                    )}.title`,
                                                )}
                                            </h6>
                                            <p>
                                                {t(
                                                    `ids.${getAccessoriesTranslationKey(
                                                        accessory.id,
                                                    )}.size`,
                                                )}
                                            </p>
                                        </div>
                                        {/* Show the tooltip only if the accessories are "Tamponamento" o "Tavolo" */}
                                        {i18n.exists(
                                            `ids.${getAccessoriesTranslationKey(
                                                accessory.id,
                                            )}.tooltip`,
                                        ) && (
                                            <Tooltip
                                                title={t(
                                                    `ids.${getAccessoriesTranslationKey(
                                                        accessory.id,
                                                    )}.tooltip`,
                                                )}
                                            >
                                                <i className="ri-information-fill" />
                                            </Tooltip>
                                        )}
                                    </Checkbox>
                                </div>
                                {chosenAccessories.filter(
                                    ({ accessory: a, position: p }) =>
                                        a.id === accessory.id && p === i,
                                ).length > 0 && (
                                    <Button
                                        className="uncheck"
                                        type="primary"
                                        shape="circle"
                                        title={t(`props.title.accessories.unselect `, {
                                            accessory: t(`ids.${accessory.id}`),
                                        })}
                                        onClick={() => {
                                            setChosenAccessories(
                                                chosenAccessories.filter(
                                                    ({ accessory: a, position: p }) =>
                                                        !(a.id === accessory.id && p === i),
                                                ),
                                            );
                                        }}
                                        icon={<i className="ri-delete-bin-fill" />}
                                    />
                                )}
                            </List.Item>
                        )}
                    />
                )}
            </>
        );
    };
    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[KitchenCabinetsView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[KitchenCabinetsView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-step-accessories">
            <StepperNavigator
                onPrevious={stepperProps.onPrevious}
                onNext={() => {
                    if (!!stepperProps.onNext)
                        stepperProps.onNext(chosenAccessories.map(({ accessory }) => accessory));
                }}
            />
            <header className="card-header large">
                <h4 className="card-title">
                    {t(`screens.selector.steps.accessories.kitchenCabinets.title`)}
                </h4>
                <p className="card-description">
                    {t(`screens.selector.steps.accessories.kitchenCabinets.description`)}
                </p>
            </header>
            <LayoutWithSidebar
                leftContent={renderLeftColumn()}
                rightContent={renderRightColumn()}
                reverse
                sizes={{
                    left: {
                        sm: 11,
                        md: 13,
                        lg: 12,
                        xl: 8,
                    },
                    right: {
                        sm: 13,
                        md: 11,
                        lg: 10,
                        xl: 9,
                    },
                }}
                offset={{
                    right: {
                        lg: 2,
                        xl: 7,
                    },
                }}
            />
        </div>
    );
};
export default KitchenCabinetsView;
