import {
    Accessory,
    Color,
    Configuration,
    Extension,
    HandleType,
    OvenType,
    PossibleDirection,
} from '@marinelli/shared-types';
import { basesResponseMock } from './handlers/basesHandler';

export const colors: Array<Color> = [
    { id: '0', imageUrl: 'https://via.placeholder.com/273x225?text=?' },
    { id: '1', imageUrl: 'https://via.placeholder.com/273x225?text=?' },
    { id: '2', imageUrl: 'https://via.placeholder.com/273x225?text=?' },
];

export const extensions: Array<Extension> = [
    { id: '0', imageUrl: 'https://via.placeholder.com/500x500?text=EXTENSION' },
    { id: '1', imageUrl: 'https://via.placeholder.com/500x500?text=EXTENSION' },
    { id: '2', imageUrl: 'https://via.placeholder.com/500x500?text=EXTENSION' },
    { id: '3', imageUrl: 'https://via.placeholder.com/500x500?text=EXTENSION' },
];

export const accessories: Array<Accessory> = [
    { id: '0', imageUrl: 'https://via.placeholder.com/500x500?text=ACCESSORY' },
    { id: '1', imageUrl: 'https://via.placeholder.com/500x500?text=ACCESSORY' },
    { id: '2', imageUrl: 'https://via.placeholder.com/500x500?text=ACCESSORY' },
    { id: '3', imageUrl: 'https://via.placeholder.com/500x500?text=ACCESSORY' },
];
export const configuration: Configuration = {
    id: '1',
    eanCode: '1',
    handleType: HandleType.INTERNAL,
    ovenType: OvenType.UNDER,
    mountDirection: PossibleDirection.L2R,
    hobs: [],
    kitchenBase: basesResponseMock[0],
    imagesUrl: [
        'https://via.placeholder.com/500x500?text=CONFIGURATION GENERAL',
        'https://via.placeholder.com/500x500?text=CONFIGURATION DIMENSIONAL',
        'https://via.placeholder.com/500x500?text=CONFIGURATION AMBIENT',
        'https://via.placeholder.com/500x500?text=CONFIGURATION BROKEN',
    ],
    sideOneExtensions: extensions,
    sideTwoExtensions: extensions,
    accessories,
    colors: [],
    colored_accessories: [],
    sideOneFinal: 300,
    sideTwoFinal: 400,
    isTopHeightOver: true,
    alternativeInstallation: true,
};
