import React, { ReactElement, useEffect } from 'react';
import { Col, Row } from 'antd';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    leftContent: ReactElement | null;
    rightContent: ReactElement | null;
    reverse?: boolean;
    sizes?: {
        left?: {
            xs?: number;
            sm?: number;
            md?: number;
            lg?: number;
            xl?: number;
        };
        right?: {
            xs?: number;
            sm?: number;
            md?: number;
            lg?: number;
            xl?: number;
        };
    };
    offset?: {
        left?: {
            xs?: number;
            sm?: number;
            md?: number;
            lg?: number;
            xl?: number;
        };
        right?: {
            xs?: number;
            sm?: number;
            md?: number;
            lg?: number;
            xl?: number;
        };
    };
}

/**
 * Component that manages the standard layout of the application, with the possibility
 * of changing the size of the two columns via the "sizes" props.
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {{ left?: { xs?: number, sm?: number, md?: number, lg?: number, xl?: number }, right?: { xs?: number, sm?: number, md?: number, lg?: number, xl?: number } }} anonymousObject.sizes - Size of columns
 * @param {{ left?: { xs?: number, sm?: number, md?: number, lg?: number, xl?: number }, right?: { xs?: number, sm?: number, md?: number, lg?: number, xl?: number } }} anonymousObject.offset - columns offset
 * @param {ReactElement} anonymousObject.leftContent - Content of the left-hand column
 * @param {ReactElement} anonymousObject.rightContent - Content of the right-hand column
 * @param {boolean} anonymousObject.reverse - Specifies whether columns are to be swapped in order in the floating resolution
 * @component
 * @category Components
 * @subcategory Layout
 * @returns {ReactElement | null}
 */
const LayoutWithSidebar = ({
    sizes,
    offset,
    leftContent,
    rightContent,
    reverse = false,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // N a v i g a t i o n   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[LayoutWithSidebar] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[LayoutWithSidebar] destroy');
        // destroy all
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();

        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------
    return (
        <Row
            gutter={[
                {
                    sm: 20,
                    md: 30,
                    lg: 40,
                    xl: 55,
                },
                0,
            ]}
            justify="center"
        >
            <Col
                xs={{
                    span: sizes?.left?.xs || 20,
                    offset: offset?.left?.xs || undefined,
                    order: reverse ? 2 : 1,
                }}
                sm={{
                    span: sizes?.left?.sm || 24,
                    offset: offset?.left?.sm || undefined,
                    order: 1,
                }}
                md={{
                    span: sizes?.left?.md || 10,
                    offset: offset?.left?.md || undefined,
                    order: 1,
                }}
                lg={{
                    span: sizes?.left?.lg || 11,
                    offset: offset?.left?.lg || undefined,
                }}
                xl={{
                    span: sizes?.left?.xl || 10,
                    offset: offset?.left?.xl || undefined,
                }}
                id="left-column"
            >
                {/* Renderizzo il contenuto 'leftContent' che viene passato come prop, rispettando le misure prestabilite */}
                {leftContent}
            </Col>
            <Col
                xs={{
                    span: sizes?.right?.xs || 22,
                    offset: offset?.right?.xs || undefined,
                    order: reverse ? 1 : 2,
                }}
                sm={{
                    span: sizes?.right?.sm || 24,
                    offset: offset?.right?.sm || undefined,
                    order: 2,
                }}
                md={{
                    span: sizes?.right?.md || 14,
                    offset: offset?.right?.md || undefined,
                    order: 2,
                }}
                lg={{
                    span: sizes?.right?.lg || 13,
                    offset: offset?.right?.lg || undefined,
                }}
                xl={{
                    span: sizes?.right?.xl || 14,
                    offset: offset?.right?.xl || undefined,
                }}
                id="right-column"
            >
                {/* Renderizzo il contenuto 'rightContent' che viene passato come prop, rispettando le misure prestabilite */}
                {rightContent}
            </Col>
        </Row>
    );
};
export default LayoutWithSidebar;
