import { Alert, Card, Divider, Empty, Spin, Table } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Footer from './layout/Footer/footer';
import Header from './layout/Header/header';
import { ContextResellerState } from '../../../redux/resellers/contextReseller/reducer';
import { StepperProps } from '../../../components/stepperNavigator/stepperNavigator';
import { ChoicesHistory } from '../../../data/interfaces';
import {
    Accessory,
    BaseType,
    Color,
    ConfigurationImages,
    OvenType,
    PossibleDirection,
} from '@marinelli/shared-types';
import { useGetPricesQuery } from '../../../hooks/react-query/useGetPricesQuery/useGetPricesQuery';
import useBases, { basesRegEx } from '../../../hooks/useBases';
import { useParams } from 'react-router-dom';
import MarinelliImage from '../../../components/layout/image/image';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    stepperProps: StepperProps;
    choicesHistory: ChoicesHistory;
}

/**
 * View representing the Welcome Page of the selector
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {StepperProps} anonymousObject.stepperProps - Stepper component props
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SummaryView = ({
    stepperProps,
    choicesHistory,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------
    // Access to the history instance that you may use to navigate.
    // const history = useHistory();

    // Get data from the authReducer store state
    const { reseller } = useSelector(
        ({ contextResellerReducer }: { contextResellerReducer: ContextResellerState }) =>
            contextResellerReducer,
    );

    /* Returns an object of key/value pairs of URL parameters
  that you may use to access match.params of the current <Route> */
    const { lang, idReseller }: any = useParams();

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // Access to bases manager hook.
    const { getAccessoriesTranslationKey } = useBases();

    const columns = [
        {
            title: t('screens.summary.quote.MCode'),
            dataIndex: 'MCode',
            key: 'MCode',
        },
        {
            title: t('screens.summary.quote.EANCode'),
            dataIndex: 'EANCode',
            key: 'EANCode',
        },
        {
            title: t('screens.summary.quote.productCode'),
            dataIndex: 'productCode',
            key: 'productCode',
        },
        {
            title: t('screens.summary.quote.description.title'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('screens.summary.quote.price'),
            dataIndex: 'price',
            key: 'price',
        },
    ];

    // Subscription to a fetch query
    const {
        data: pricesResponse,
        isSuccess: isFetchingPricesSucceded,
        isFetching: isFetchingPrices,
        isError: isFetchingPricesFailed,
    } = useGetPricesQuery({
        ids: [
            ...[choicesHistory.configuration?.id || ''],
            ...[choicesHistory.hob?.id || ''],
            ...(choicesHistory.configuration?.colored_accessories || []).map(
                (a: Accessory) => a.id,
            ),
        ],
        resellerId: `${idReseller}`,
        lang: `${lang}`,
    });

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Get the translation key name starting from configuration id
     *
     * @function
     * @returns {string}
     */
    const getKitchenDescriptionTranslationKey = (): string => {
        return `${choicesHistory.configuration?.id.replace(basesRegEx, '$1$2$3$4***$6$7$8*$10')}`;
    };

    /**
     * Get the translation key name starting from configuration id
     *
     * @function
     * @returns {string}
     */
    const getConfigurationDescriptionTranslationKey = (): string => {
        return `${choicesHistory.configuration?.id.replace(basesRegEx, '$1*****$4*********')}`;
    };

    /**
     * Get the translation key name starting from configuration id
     *
     * @function
     * @returns {string}
     */
    const getKitchenBaseDescriptionTranslationKey = (): string => {
        return `${choicesHistory.configuration?.id.replace(basesRegEx, '$1$2$3$4$5$6$7$8**')}`;
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render the contents of the colors table
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderColorsTable = (): ReactElement | null => {
        if (!choicesHistory.colors) return null;
        return (
            <>
                {' '}
                <div className="colors">
                    <h5 className="secondary-section">{t('screens.summary.table.colors.title')}</h5>
                    <div className="table-container">
                        {choicesHistory.colors.map((c: Color, i: number) => (
                            <div className="table-content">
                                <h6>
                                    {t(
                                        `screens.summary.table.colors.${
                                            i === 0 ? 'base' : i === 1 ? 'top' : 'kitchenCabinet'
                                        }`,
                                    )}
                                </h6>
                                <p>{t(`ids.${c.id}`)}</p>
                                <MarinelliImage alt={`${c.id} color image`} src={[c.imageUrl]} />
                            </div>
                        ))}
                    </div>
                </div>
                <Divider />
            </>
        );
    };

    /**
     * Render the contents of the accessories table
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderAccessoriesTable = (): ReactElement | null => {
        const areThereChosenAccessories: boolean =
            !!choicesHistory.configuration?.colored_accessories &&
            choicesHistory.configuration.colored_accessories.length > 0;
        const isThereChosenHob: boolean = !!choicesHistory.hob;

        if (!(areThereChosenAccessories || isThereChosenHob)) return null;

        return (
            <>
                {' '}
                <div className="accessories">
                    <h5 className="secondary-section">
                        {t('screens.summary.table.accessories.title')}
                    </h5>
                    <h6>{t('screens.summary.table.accessories.accessoryType')}</h6>
                    <div className="accessories-container">
                        {isThereChosenHob && (
                            <div className="accessories-content">
                                <MarinelliImage src={[choicesHistory.hob?.imageUrl || '']} />
                                <div className="accessories-text">
                                    <i className="ri-checkbox-blank-circle-fill" />
                                    <p>{t(`ids.${choicesHistory.hob?.id}`)}</p>
                                </div>
                            </div>
                        )}
                        {areThereChosenAccessories &&
                            (choicesHistory.configuration?.colored_accessories || []).map(
                                (a: Accessory) => (
                                    <div className="accessories-content">
                                        <MarinelliImage src={[a.imageUrl]} />
                                        <div className="accessories-text">
                                            <i className="ri-checkbox-blank-circle-fill" />
                                            <p>
                                                {t(`ids.${a.id}`)}
                                                {/* {t(`ids.${getAccessoriesTranslationKey(a.id)}.title`)} */}
                                                {/* <span>
                                            {' '}
                                            - ({t(`ids.${getAccessoriesTranslationKey(a.id)}.size`)}
                                            )
                                        </span> */}
                                            </p>
                                        </div>
                                    </div>
                                ),
                            )}
                    </div>
                </div>
                <Divider />
            </>
        );
    };

    /**
     * Render the contents of the accessories table
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderPricesTable = (): ReactElement | null => {
        // Data is fetching: return spinner UI element
        if (isFetchingPrices)
            return (
                <div className="loader" style={{ height: 200 }}>
                    <Spin size="large" />
                </div>
            );

        // Fetching is failed: return no data UI element
        if (isFetchingPricesFailed)
            return (
                <Empty
                    description={t('screens.selector.steps.prices.noData')}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            );

        if (isFetchingPricesSucceded && !!pricesResponse) {
            // Configuration row
            let dataSource: Array<any> = [
                {
                    key: choicesHistory.configuration?.id,
                    MCode: choicesHistory.configuration?.id,
                    EANCode: choicesHistory.configuration?.eanCode,
                    productCode: pricesResponse[0].productUrl ? (
                        <a href={pricesResponse[0].productUrl} target="_blanck">
                            {pricesResponse[0].internalResellerCode}
                        </a>
                    ) : (
                        pricesResponse[0].internalResellerCode
                    ),
                    description: t(
                        `screens.summary.quote.description.${getConfigurationDescriptionTranslationKey()}`,
                    ),
                    price: pricesResponse[0].price,
                },
            ];

            // Hob row
            if (!!choicesHistory.hob)
                dataSource.push({
                    key: choicesHistory.hob?.id,
                    MCode: choicesHistory.hob?.id, // TODO
                    // EANCode: choicesHistory.configuration?.eanCode, // TODO
                    productCode: pricesResponse[1].productUrl ? (
                        <a href={pricesResponse[1].productUrl} target="_blanck">
                            {pricesResponse[1].internalResellerCode}
                        </a>
                    ) : (
                        pricesResponse[1].internalResellerCode
                    ),
                    description: t(`screens.summary.quote.description.${choicesHistory.hob?.id}`),
                    price: pricesResponse[1].price,
                });

            // Accessories rows
            if (
                !!choicesHistory.configuration?.colored_accessories &&
                choicesHistory.configuration.colored_accessories.length > 0
            )
                dataSource = [
                    ...dataSource,
                    ...choicesHistory.configuration.colored_accessories.map(
                        (a: Accessory, i: number) => {
                            return {
                                key: pricesResponse[i + 2].productId,
                                MCode: pricesResponse[i + 2].productId,
                                // EANCode: "",
                                productCode: pricesResponse[i + 2].productUrl ? (
                                    <a href={pricesResponse[i + 2].productUrl} target="_blanck">
                                        {pricesResponse[i + 2].internalResellerCode}
                                    </a>
                                ) : (
                                    pricesResponse[i + 2].internalResellerCode
                                ),
                                description: t(
                                    `screens.summary.quote.description.${getAccessoriesTranslationKey(
                                        a.id,
                                    )}`,
                                ),
                                price: pricesResponse[i + 2].price,
                            };
                        },
                    ),
                ];

            return (
                <div>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: true }}
                    />
                </div>
            );
        }
        return null;
    };

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component

        console.log('[SummaryView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SummaryView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="selector-summary" className="summary-container">
            <Header />
            <div className="summary-content-container">
                <Card>
                    <div className="intro-screen">
                        <h1 title={t('props.title.summary.configurationName')}>
                            {t(
                                `ids.${choicesHistory.configuration?.id.replace(
                                    basesRegEx,
                                    '$1$2*****$5***$8**',
                                )}`,
                            )}
                        </h1>
                        <div
                            title={t('props.title.summary.configurationImage')}
                            className="intro-image"
                            style={{
                                backgroundImage: `url(${
                                    choicesHistory.configuration?.imagesUrl[
                                        ConfigurationImages.AMBIENT
                                    ]
                                })`,
                            }}
                        >
                            <div className="intro-scroll">
                                <p>{t('screens.summary.intro.description')}</p>
                                <i className="ri-arrow-down-s-line" />
                            </div>
                        </div>
                    </div>
                    <div className="main-screen">
                        <div className="kitchen-model-container">
                            <div className="kitchen-model-text">
                                <h4>{t('screens.summary.kitchenModel.title')}</h4>
                                <p>{t(`ids.${getKitchenDescriptionTranslationKey()}`)}</p>
                            </div>

                            <div className="kitchen-model-images">
                                <MarinelliImage
                                    src={[
                                        choicesHistory.configuration?.imagesUrl[
                                            choicesHistory.direction === PossibleDirection.R2L
                                                ? ConfigurationImages.GENERAL_REV
                                                : ConfigurationImages.GENERAL
                                        ] || '',
                                    ]}
                                />
                                <MarinelliImage
                                    src={[
                                        choicesHistory.configuration?.imagesUrl[
                                            choicesHistory.direction === PossibleDirection.R2L
                                                ? ConfigurationImages.DIMENSIONAL_REV
                                                : ConfigurationImages.DIMENSIONAL
                                        ] || '',
                                    ]}
                                />
                            </div>
                            {choicesHistory.configuration?.isTopHeightOver && (
                                <Alert
                                    message={t('screens.summary.kitchenModel.note')}
                                    type="error"
                                    showIcon
                                    icon={<i className="ri-alert-fill" />}
                                />
                            )}
                        </div>
                        <div className="summary-tables-container">
                            <div className="summary">
                                <h4 className="main-section">{t('screens.summary.table.title')}</h4>
                                {renderColorsTable()}
                                <div className="table-container">
                                    <div className="table-content">
                                        <h6>{t('screens.summary.table.kitchenType')}</h6>
                                        <p>
                                            {t(`ids.${getKitchenBaseDescriptionTranslationKey()}`)}
                                        </p>
                                    </div>
                                    <div className="table-content">
                                        <h6>{t('screens.summary.table.filledSpace.title')}</h6>
                                        {choicesHistory.layout === BaseType.LINEAR ? (
                                            <p>{choicesHistory.configuration?.sideOneFinal} cm</p>
                                        ) : (
                                            <p>
                                                {t(`screens.summary.table.filledSpace.side_1`, {
                                                    size: choicesHistory.configuration
                                                        ?.sideOneFinal,
                                                })}
                                            </p>
                                        )}
                                        {!!choicesHistory.maxLength2 && (
                                            <p>
                                                {t(`screens.summary.table.filledSpace.side_2`, {
                                                    size: choicesHistory.configuration
                                                        ?.sideTwoFinal,
                                                })}
                                            </p>
                                        )}
                                    </div>
                                    <div className="table-content">
                                        <h6>{t('screens.summary.table.handleType')}</h6>
                                        <p>
                                            {t(
                                                `screens.selector.steps.structure.handle.card.${choicesHistory.handle}.title`,
                                            )}
                                        </p>
                                    </div>

                                    <div className="table-content">
                                        <h6>{t('screens.summary.table.ovenPosition.title')}</h6>
                                        <p>
                                            {' '}
                                            {t(
                                                `screens.selector.steps.structure.oven.card.${
                                                    choicesHistory.oven || OvenType.COLUMN
                                                }.title`,
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <Divider />

                            {renderAccessoriesTable()}
                            <div className="quote">
                                <h5 className="secondary-section">
                                    {t('screens.summary.quote.title')}
                                </h5>
                                {renderPricesTable()}
                                <div className="order-note">
                                    <p>{t('screens.summary.quote.note.title')}</p>
                                    <p>
                                        {t('screens.summary.quote.note.option_1', {
                                            reseller: reseller?.name,
                                        })}
                                    </p>
                                    <p>
                                        {t('screens.summary.quote.note.option_2', {
                                            websiteUrl: reseller?.websiteUrl,
                                        })}
                                    </p>

                                    <p className="special_note">
                                        {t('screens.summary.quote.note.lang_special') !==
                                        'screens.summary.quote.note.lang_special'
                                            ? t('screens.summary.quote.note.lang_special')
                                            : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            {!!pricesResponse && (
                <Footer
                    pricesInformations={pricesResponse}
                    choicesHistory={choicesHistory}
                    stepperProps={stepperProps}
                />
            )}
        </div>
    );
};
export default SummaryView;
