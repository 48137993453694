import React, { ReactElement, useEffect, useState } from 'react';
import { SELECTOR_STEPS, STEP_COMPONENTS } from '../../../../../data/enums';
import { Step, ChoicesHistory } from '../../../../../data/interfaces';
import SelectorStepBaseView from '../../steps/bases/bases';
import {
    Accessory,
    Color,
    Configuration,
    Extension,
    ExtensionSide,
    HandleType,
    Hob,
    KitchenBase,
    OvenType,
    PossibleDirection,
} from '@marinelli/shared-types';
import HandleTypeView from '../../steps/handleType/handleType';
import OvenPositionView from '../../steps/ovenPosition/ovenPosition';
import HobView from '../../steps/hobType/hobType';
import KitchenCabinetsView from '../../steps/accessories/accessories';
import Stepper from '../components/stepper';
import SelectorStepExtensionsView from '../../steps/extensions/extensions';
import StepTypesMaxLengthView, { MaxLengthFormData } from '../../steps/maxLength/maxLength';
import SelectorColorsView from '../../steps/colors/colors';
import SummaryView from '../../../summary';
import SideMenu from '../../../../../components/layout/sideMenu/sideMenu';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------


interface Props {
    steps: Array<Step>;
    choicesHistory: ChoicesHistory;
    initialStep?: SELECTOR_STEPS;
    initialStepContent?: STEP_COMPONENTS;
    onHistoryUpdate: (history: ChoicesHistory) => void;
}

/**
 * COMMENT ME
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {Array<Step>} anonymousObject.steps - Steps objects
 * @param {SELECTOR_STEPS} anonymousObject.initialStep - Default step from which to start
 * @param {SELECTOR_STEPS} anonymousObject.initialStepContent - Default step content from which to start
 * @param {(history: ChoicesHistory) => void} anonymousObject.onHistoryUpdate - Called when history is to update
 * @param {ChoicesHistory} anonymousObject.choicesHistory - Actual choices
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const SelectorIndexLinearFlowView = ({
    steps,
    initialStep,
    initialStepContent,
    choicesHistory,
    onHistoryUpdate,
    ...restProps
}: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    const [currentStep, setCurrentStep] = useState<{
        step: SELECTOR_STEPS;
        content: STEP_COMPONENTS;
    }>({
        step: initialStep || SELECTOR_STEPS.TYPES,
        content: initialStepContent || STEP_COMPONENTS.LENGTH_1,
    });

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Returns the position property of the current step
     *
     * @param {any} id - Step identifier
     * @function
     * @returns {number}
     */
    const getStepPosition = (id?: any): number => {
        const cs: Step | undefined = steps.find((s) => s.id === (id || currentStep.step));
        return cs ? cs.position || 0 : 0;
    };

    /**
     * Returns to the chosen step, deleting the appropriate history
     *
     * @param {STEP_COMPONENTS} choosenStepContent - Step content identifier
     * @function
     * @returns {void}
     */
    const handleBackToStep = (choosenStepContent: STEP_COMPONENTS): void => {
        let choosenStep: SELECTOR_STEPS;

        switch (choosenStepContent) {
            case STEP_COMPONENTS.ACCESSORIES:
            default:
                choosenStep = SELECTOR_STEPS.ACCESSORIES;

                // Update history
                onHistoryUpdate({
                    configuration: choicesHistory.configuration,
                    layout: choicesHistory.layout,
                    maxLength1: choicesHistory.maxLength1,
                    handle: choicesHistory.handle,
                    oven: choicesHistory.oven,
                    base: choicesHistory.base,
                    direction: choicesHistory.direction,
                    extensions1: choicesHistory.extensions1,
                    hob: choicesHistory.hob,
                });
                break;
            case STEP_COMPONENTS.HOB:
                choosenStep = SELECTOR_STEPS.ACCESSORIES;

                // Update history
                onHistoryUpdate({
                    configuration: choicesHistory.configuration,
                    layout: choicesHistory.layout,
                    maxLength1: choicesHistory.maxLength1,
                    handle: choicesHistory.handle,
                    oven: choicesHistory.oven,
                    base: choicesHistory.base,
                    direction: choicesHistory.direction,
                    extensions1: choicesHistory.extensions1,
                });
                break;
            case STEP_COMPONENTS.EXTENSIONS_1:
                choosenStep = SELECTOR_STEPS.EXTENSIONS;

                // Update history
                onHistoryUpdate({
                    configuration: choicesHistory.configuration,
                    layout: choicesHistory.layout,
                    maxLength1: choicesHistory.maxLength1,
                    handle: choicesHistory.handle,
                    oven: choicesHistory.oven,
                    base: choicesHistory.base,
                    direction: choicesHistory.direction,
                });
                break;
            case STEP_COMPONENTS.BASES:
                choosenStep = SELECTOR_STEPS.BASE;

                // Update history
                onHistoryUpdate({
                    layout: choicesHistory.layout,
                    maxLength1: choicesHistory.maxLength1,
                    handle: choicesHistory.handle,
                    oven: choicesHistory.oven,
                });
                break;
            case STEP_COMPONENTS.OVEN:
                choosenStep = SELECTOR_STEPS.STRUCTURE;

                // Update history
                onHistoryUpdate({
                    layout: choicesHistory.layout,
                    maxLength1: choicesHistory.maxLength1,
                    handle: choicesHistory.handle,
                });
                break;
            case STEP_COMPONENTS.HANDLE:
                choosenStep = SELECTOR_STEPS.STRUCTURE;

                // Update history
                onHistoryUpdate({
                    layout: choicesHistory.layout,
                    maxLength1: choicesHistory.maxLength1,
                });
                break;
            case STEP_COMPONENTS.LENGTH_1:
                choosenStep = SELECTOR_STEPS.TYPES;

                // Update history
                onHistoryUpdate({
                    layout: choicesHistory.layout,
                });
                break;
            case STEP_COMPONENTS.LAYOUT:
                choosenStep = SELECTOR_STEPS.TYPES;

                // Update history
                onHistoryUpdate({});
                break;
        }
        // Update current step
        setCurrentStep({
            step: choosenStep,
            content: choosenStepContent,
        });
    };

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    /**
     * Render the content of Types step base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderTypesStepContent = (): ReactElement | null => {
        return (
            <>
                <Stepper steps={steps} stepPosition={getStepPosition()} />
                <StepTypesMaxLengthView
                    side={ExtensionSide.SIDE_ONE}
                    stepperProps={{
                        onPrevious: () => {
                            // Update history
                            onHistoryUpdate({});
                        },
                    }}
                    key={`side${currentStep.content === STEP_COMPONENTS.LENGTH_1 ? '1' : '2'}`}
                    choicesHistory={choicesHistory}
                    defaultData={
                        !!choicesHistory.maxLength1
                            ? { maxLength: choicesHistory.maxLength1 }
                            : undefined
                    }
                    onSubmit={({ maxLength: choosenLength }: MaxLengthFormData) => {
                        // Update history
                        onHistoryUpdate({ ...choicesHistory, ...{ maxLength1: choosenLength } });

                        // Update current step
                        setCurrentStep({
                            step: SELECTOR_STEPS.STRUCTURE,
                            content: STEP_COMPONENTS.HANDLE,
                        });
                    }}
                />
            </>
        );
    };

    /**
     * Render the content of Structure step base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderStructureStepContent = (): ReactElement | null => {
        switch (currentStep.content) {
            case STEP_COMPONENTS.HANDLE:
            default:
                return (
                    <>
                        <Stepper steps={steps} stepPosition={getStepPosition()} />
                        <HandleTypeView
                            stepperProps={{
                                onPrevious: () => {
                                    const { maxLength1, ...rest } = choicesHistory;

                                    // Update history
                                    onHistoryUpdate(rest);

                                    // Update current step
                                    setCurrentStep({
                                        step: SELECTOR_STEPS.TYPES,
                                        content: STEP_COMPONENTS.LENGTH_1,
                                    });
                                },
                            }}
                            choicesHistory={choicesHistory}
                            onSelect={(choosenHandle?: HandleType) => {
                                // Update current step
                                setCurrentStep({
                                    step: SELECTOR_STEPS.STRUCTURE,
                                    content: STEP_COMPONENTS.OVEN,
                                });

                                // Update history
                                onHistoryUpdate({
                                    ...choicesHistory,
                                    ...{ handle: choosenHandle },
                                });
                            }}
                        />
                    </>
                );
            case STEP_COMPONENTS.OVEN:
                return (
                    <>
                        <Stepper steps={steps} stepPosition={getStepPosition()} />{' '}
                        <OvenPositionView
                            choicesHistory={choicesHistory}
                            stepperProps={{
                                onPrevious: () => {
                                    const { handle, ...rest } = choicesHistory;

                                    // Update history
                                    onHistoryUpdate(rest);

                                    // Update current step
                                    setCurrentStep({
                                        step: SELECTOR_STEPS.STRUCTURE,
                                        content: STEP_COMPONENTS.HANDLE,
                                    });
                                },
                            }}
                            onSelect={(choosenOven?: OvenType) => {
                                // Update current step
                                setCurrentStep({
                                    step: SELECTOR_STEPS.BASE,
                                    content: STEP_COMPONENTS.BASES,
                                });

                                // Update history
                                onHistoryUpdate({
                                    ...choicesHistory,
                                    ...{
                                        oven: choosenOven,
                                    },
                                });
                            }}
                        />
                    </>
                );
        }
    };

    /**
     * Render the content of Bases step base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderBasesStepContent = (): ReactElement | null => {
        return (
            <>
                <Stepper steps={steps} stepPosition={getStepPosition()} />
                <SelectorStepBaseView
                    stepperProps={{
                        onPrevious: () => {
                            const { oven, ...rest } = choicesHistory;

                            // Update history
                            onHistoryUpdate(rest);

                            // Update current step
                            setCurrentStep({
                                step: SELECTOR_STEPS.STRUCTURE,
                                content: STEP_COMPONENTS.OVEN,
                            });
                        },
                    }}
                    choicesHistory={choicesHistory}
                    onSelect={(choosenBase: KitchenBase, choosenDirection: PossibleDirection) => {
                        // Update current step
                        setCurrentStep({
                            step: SELECTOR_STEPS.EXTENSIONS,
                            content: STEP_COMPONENTS.EXTENSIONS_1,
                        });

                        // Update history
                        onHistoryUpdate({
                            ...choicesHistory,
                            ...{
                                base: choosenBase,
                                direction: choosenDirection,
                            },
                        });
                    }}
                />
            </>
        );
    };

    /**
     * Render the content of Extensions step base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderExtensionsStepContent = (): ReactElement | null => {
        return (
            <>
                <Stepper steps={steps} stepPosition={getStepPosition()} />
                <SelectorStepExtensionsView
                    side={ExtensionSide.SIDE_ONE}
                    onUpdateConfiguration={(newConfiguration?: Configuration) =>
                        onHistoryUpdate({
                            ...choicesHistory,
                            ...{ configuration: newConfiguration },
                        })
                    }
                    {...restProps}
                    stepperProps={{
                        onPrevious: () => {
                            const { base, ...rest } = choicesHistory;

                            // Update history
                            onHistoryUpdate(rest);

                            // Update current step
                            setCurrentStep({
                                step: SELECTOR_STEPS.BASE,
                                content: STEP_COMPONENTS.BASES,
                            });
                        },
                        onNext: (choosenExtensions: Array<Extension>) => {
                            // Update history
                            onHistoryUpdate({
                                ...choicesHistory,
                                ...{ extensions1: choosenExtensions },
                            });
                            // Update current step
                            setCurrentStep({
                                step: SELECTOR_STEPS.ACCESSORIES,
                                content:
                                    !!choicesHistory.configuration?.hobs &&
                                    choicesHistory.configuration.hobs.length > 0
                                        ? STEP_COMPONENTS.HOB
                                        : STEP_COMPONENTS.ACCESSORIES,
                            });
                        },
                    }}
                    key="side1"
                    choicesHistory={choicesHistory}
                />
            </>
        );
    };

    /**
     * Render the content of Accessories step base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderAccessoriesStepContent = (): ReactElement | null => {
        switch (currentStep.content) {
            case STEP_COMPONENTS.HOB:
            default:
                return (
                    <>
                        <Stepper steps={steps} stepPosition={getStepPosition()} />
                        <HobView
                            stepperProps={{
                                onPrevious: () => {
                                    const { extensions1, ...rest } = choicesHistory;

                                    // Update history
                                    onHistoryUpdate(rest);

                                    // Update current step
                                    setCurrentStep({
                                        step: SELECTOR_STEPS.EXTENSIONS,
                                        content: STEP_COMPONENTS.EXTENSIONS_1,
                                    });
                                },
                            }}
                            choicesHistory={choicesHistory}
                            onSelect={(choosenHob?: Hob) => {
                                // Update current step
                                setCurrentStep({
                                    step: SELECTOR_STEPS.ACCESSORIES,
                                    content: STEP_COMPONENTS.ACCESSORIES,
                                });

                                // Update history
                                onHistoryUpdate({
                                    ...choicesHistory,
                                    ...{ hob: choosenHob },
                                });
                            }}
                        />
                    </>
                );
            case STEP_COMPONENTS.ACCESSORIES:
                return (
                    <>
                        <Stepper steps={steps} stepPosition={getStepPosition()} />
                        <KitchenCabinetsView
                            stepperProps={{
                                onPrevious: () => {
                                    const { hob, ...rest } = choicesHistory;

                                    // Update history
                                    onHistoryUpdate(rest);

                                    // Update current step
                                    setCurrentStep({
                                        step:
                                            !!choicesHistory.configuration?.hobs &&
                                            choicesHistory.configuration.hobs.length > 0
                                                ? SELECTOR_STEPS.ACCESSORIES
                                                : SELECTOR_STEPS.EXTENSIONS,
                                        content:
                                            !!choicesHistory.configuration?.hobs &&
                                            choicesHistory.configuration.hobs.length > 0
                                                ? STEP_COMPONENTS.HOB
                                                : STEP_COMPONENTS.EXTENSIONS_1,
                                    });
                                },
                                onNext: (choosenAccessories: Array<Accessory>) => {
                                    // Update current step
                                    setCurrentStep({
                                        step: SELECTOR_STEPS.COLORS,
                                        content: STEP_COMPONENTS.COLORS,
                                    });

                                    // Update history
                                    onHistoryUpdate({
                                        ...choicesHistory,
                                        ...{ accessories: choosenAccessories },
                                    });
                                },
                            }}
                            choicesHistory={choicesHistory}
                        />
                    </>
                );
        }
    };

    /**
     * Render the content of Accessories step base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderColorsStepContent = (): ReactElement | null => {
        return (
            <>
                <Stepper steps={steps} stepPosition={getStepPosition()} />
                <SelectorColorsView
                    onUpdateConfiguration={(newConfiguration?: Configuration) =>
                        onHistoryUpdate({
                            ...choicesHistory,
                            ...{ configuration: newConfiguration },
                        })
                    }
                    stepperProps={{
                        onPrevious: () => {
                            const { accessories, ...rest } = choicesHistory;

                            // Update history
                            onHistoryUpdate(rest);

                            // Update current step
                            setCurrentStep({
                                step: SELECTOR_STEPS.ACCESSORIES,
                                content: STEP_COMPONENTS.ACCESSORIES,
                            });
                        },
                        onNext: (choosenColors: Array<Color>) => {
                            // Update history
                            onHistoryUpdate({
                                ...choicesHistory,
                                ...{ colors: choosenColors },
                            });
                            // Update current step
                            setCurrentStep({
                                step: SELECTOR_STEPS.SUMMARY,
                                content: STEP_COMPONENTS.SUMMARY,
                            });
                        },
                    }}
                    choicesHistory={choicesHistory}
                />
            </>
        );
    };

    /**
     * Render the content of Summary page base on current choises
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderSummaryContent = (): ReactElement | null => {
        return (
            <SummaryView
                stepperProps={{
                    onPrevious: () => {
                        const { colors, ...rest } = choicesHistory;

                        // Update history
                        onHistoryUpdate(rest);

                        // Update current step
                        setCurrentStep({
                            step: SELECTOR_STEPS.COLORS,
                            content: STEP_COMPONENTS.COLORS,
                        });
                    },
                }}
                choicesHistory={choicesHistory}
            />
        );
    };

    /**
     * Render the contents of the current (active) step
     *
     * @function
     * @returns {ReactElement | null}
     */
    const renderActiveStep = (): ReactElement | null => {
        switch (currentStep.step) {
            case SELECTOR_STEPS.TYPES:
            default:
                return renderTypesStepContent();
            case SELECTOR_STEPS.STRUCTURE:
                return renderStructureStepContent();
            case SELECTOR_STEPS.BASE:
                return renderBasesStepContent();
            case SELECTOR_STEPS.EXTENSIONS:
                return renderExtensionsStepContent();
            case SELECTOR_STEPS.ACCESSORIES:
                return renderAccessoriesStepContent();
            case SELECTOR_STEPS.COLORS:
                return renderColorsStepContent();
            case SELECTOR_STEPS.SUMMARY:
                return renderSummaryContent();
        }
    };
    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[SelectorIndexLinearFlowView] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[SelectorIndexLinearFlowView] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <div data-testid="linear-flow">
            {renderActiveStep()}
            {currentStep.step !== SELECTOR_STEPS.SUMMARY && (
                <SideMenu onChangeCurrentStep={handleBackToStep} choicesHistory={choicesHistory} />
            )}
        </div>
    );
};
export default SelectorIndexLinearFlowView;
