import { Steps } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MarinelliImage from '../../../../../components/layout/image/image';
import { Step } from '../../../../../data/interfaces';
import { ContextResellerState } from '../../../../../redux/resellers/contextReseller/reducer';

// -----------------------------------------------------------------
// I n t e r f a c e s
// -----------------------------------------------------------------

interface Props {
    steps: Array<Step>;
    stepPosition: number;
}

/**
 * COMMENT ME
 *
 * @param {GenericObject} anonymousObject - Input object used for deconstruction
 * @param {Array<Step>} anonymousObject.steps - Steps to render
 * @param {number} anonymousObject.stepPosition - Current step position
 * @component
 * @category Components
 * @subcategory Screens
 * @returns {ReactElement | null}
 */
const Stepper = ({ steps, stepPosition, ...restProps }: Props): ReactElement | null => {
    // -----------------------------------------------------------------
    // u s e S e l e c t o r   m e t h o d s  (redux)
    // -----------------------------------------------------------------

    // Get data from the authReducer store state
    const { reseller } = useSelector(
        ({ contextResellerReducer }: { contextResellerReducer: ContextResellerState }) =>
            contextResellerReducer,
    );

    // Access to i18n instance that you may use to translate your content.
    const { t } = useTranslation();

    // -----------------------------------------------------------------
    // L o c a l   v a r s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // H o o k s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // S t a t e
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e f s  (DOM)
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // W o r k i n g   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // R e n d e r   m e t h o d s
    // -----------------------------------------------------------------

    // -----------------------------------------------------------------
    // L i f e c y c l e
    // -----------------------------------------------------------------
    /**
     * This method is called the first time the component is mounted
     *
     * @function
     * @returns {void}
     */
    const init = (): void => {
        // init component
        console.log('[Stepper] init');
    };

    /**
     * This method is called when the component is unmounted
     *
     * @function
     * @returns {void}
     */
    const destroy = (): void => {
        // destroy component
        console.log('[Stepper] destroy');
    };

    // -----------------------------------------------------------------
    // u s e E f f e c t   m e t h o d s
    // -----------------------------------------------------------------
    /**
     * This hook is called once when the component is mounted
     */
    useEffect(() => {
        init();
        return () => {
            destroy();
        };
    }, []);

    // -----------------------------------------------------------------
    // T e m p l a t e
    // -----------------------------------------------------------------

    return (
        <header className="steps-wrapper">
            {!reseller?.is_embedded_in_iframe && (
                <MarinelliImage src={[reseller?.logoUrl || '']} alt={`${reseller?.name} logo`} />
            )}
            <Steps
                direction="horizontal"
                current={stepPosition}
                percent={(stepPosition / steps.length) * 100}
                size="small"
            >
                {steps
                    .filter((s) => !s.hidden)
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((s) => (
                        <Steps.Step
                            data-testid="step"
                            key={s.id}
                            title={t(s.title)}
                            description={s.description}
                        />
                    ))}
            </Steps>
        </header>
    );
};
export default Stepper;
