import { IGetColors } from '@marinelli/shared-types';
import { useQuery, UseQueryResult } from 'react-query';
import { ColorsResponse } from '../../../data/interfaces';
import { QUERIES_KEYS } from '../../../modules/common/data/enums';
import { getColors } from '../../../redux/colors/action';
import useQueries from '../../useQueries';

/**
 * @param {IGetColors} data - Input data
 * @param {boolean} isEnabled - Defines whether the query should be executed immediately
 * @returns {UseQueryResult<ColorsResponse, any>}
 */
export const useGetColorsQuery = (
    data: IGetColors,
    isEnabled: boolean = false,
): UseQueryResult<ColorsResponse, any> => {
    const { getFetchRequestQueryParams }: any = useQueries();

    return useQuery(
        [QUERIES_KEYS.GET_COLORS],
        async () => {
            return getColors(data);
        },
        getFetchRequestQueryParams({
            enabled: isEnabled,
            retry: !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'development',
        }),
    );
};
